import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function HeaderLoggedIn() {
  return (
    <>
      {['sm', ].map((expand) => (
        <Navbar key={expand} expand={expand} fixed='top' bg={'dark'} className="bg-body-tertiary mb-4">
          <Container fluid>
            <Navbar.Brand href="#">Buradago</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Buradago
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link as={Link} to="/home-page">Anasayfa</Nav.Link>
                  {/* <Nav.Link as={Link} to="/giris">Giriş</Nav.Link>
                  <Nav.Link as={Link} to="uye-ol-page">Üye Ol</Nav.Link> */}
                  {/* <Nav.Link as={Link} to="#action2">Etkinlik İste</Nav.Link> Reactivate this after we apply and get the sanal pos*/}
                  {/* <NavDropdown
                    title="Kategorıde ara"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Etkinlik Ara"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Ara</Button>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default HeaderLoggedIn;