import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelector(props) {
  const [value, setValue] = useState('Turkey')
//   const options = useMemo(() => countryList().getData(), [])
  const options = useMemo(() => countryList().getLabels(), [])

  const changeHandler = value => {
    setValue(value)

    //provide name of country to parent component
    // setCountryLabel(countryList.getLabel(value));
    props.setCountryLabel(value);
  }

//   return <Select className='w-full '
//    options={options} value={value} onChange={changeHandler} />
 return(
 <select name="" id="" 
 disabled={true}
 value={value}
 onChange={changeHandler}
 className='text-gray-600 rounded-md w-full'>
    
    {options&&options.map(
        (countryName) => (
            <option style={{width:'1rem'}} key={countryName} value={countryName}>{countryName}</option>
        )
    )}
  </select>
 ) 
}

export default CountrySelector
