import React from 'react'

const DevComp = () => {
  return (
    <div style={{marginTop:"70px"}} 
    className='text-gray-50 mt-10 bg-red-400 h-screen w-screen'>
        DevComp
    </div>
  )
}

export default DevComp;