import React from 'react'

const ErrorComp = (props) => {
  return (
    <div>
        {props.errors&&
        props.errors.map(error=>(
            <p className='text-white bg-red-500'
            >{error[0]}</p>
        ))
        }
    </div>
  )
}

export default ErrorComp