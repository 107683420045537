import React, { useState,useRef, useContext, useEffect  } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { withRouter, useNavigate, useParams } from "react-router-dom";
import '@coreui/coreui/dist/css/coreui.min.css';

import "../App.css";
import MyFooter from '../components/footer';
// import { CContainer } from '@coreui/react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { AUTH_COOKIE_LINK, LOGIN_URL_PROD, PASSWORD_RESET_URL, generalPostMethod } from '../components/requests';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;



const PasswordResetScreen = () => { 
const [newPassword, setnewPassword] = useState('');
const [confirmPassword, setconfirmPassword] = useState('');
const [resetToken, setresetToken] = useState('');
const [ismasked1, setismasked1] = useState(false)
const [ismasked2, setismasked2] = useState(false)
const [errorMessage, setErrorMessage] = useState('');

const navigateThere = useNavigate();
const urlParam = useParams();

// const { setAuth } = useContext(AuthContext);
const userRef = useRef();
const errRef = useRef();

// const [errMsg, setErrMsg] = useState('');
const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      setErrorMessage('');
      if (newPassword !== confirmPassword){
        setErrorMessage("Girdiğniz şifreler aynı olması gerekli") 
        
      } else{
        generalPostMethod(PASSWORD_RESET_URL,
          {passT:resetToken,
            newPassword:newPassword
          }).then((response) => { 
          if (response.status===200) {
            setSuccess(true);
            alert("Şifrenizi başarıyla değiştirildi!")
              navigateThere("/giris")
          } else {
            if (response.data) {
              setErrorMessage(response.data.message)
            } else {
              setErrorMessage("Beklenmeyen bir hata oluşmuş!")
            }
          }
         }) };
      }

  useEffect(() => { 
    setresetToken(urlParam.resetToken)
   },[])

  return (
    
    <div  className="login-page">
      {errorMessage&&
      <p className='bg-red-400'>{"Hata: "+errorMessage}</p> 
      }
    <h2 style={{color:'white'}}>Şifre Yenileme</h2> <br/>
    <Form onSubmit={handleSubmit}>
      
      <Form.Group className="mb-3" >
        <Form.Control type={ismasked1?'password':'text'}
         value={newPassword}
         required
         onChange={(e) => setnewPassword(e.target.value)}
         placeholder="Yeni Şifre" />
      </Form.Group>

      <Form.Group className="mb-3" >
        {/* <Form.Label>Şifre</Form.Label> */}
        <Form.Control type={ismasked2?'password':'text'}
         value={confirmPassword} 
         required
         onChange={(e) => setconfirmPassword(e.target.value)}
          placeholder="Yeni Şifre Tekrarı" />
      </Form.Group>
      <Button className=''  type="submit">
        Gönder
   
      </Button>
    </Form>
    </div>
    
    
    
   
  );
};

export default PasswordResetScreen;
