import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSingleEvent } from '../requests';

const PaymentSuccess = () => {
    const navigateThere = useNavigate();
    const params = useParams();

    const [eventId, seteventId] = useState('');
    const [EventInfo, setEventInfo] = useState({});


    //functions

    // returns true (showbutton) if purchase end time of event is still not due yet
    function determineBuyButton(event,timestamp) {
        
        let timeArr = event.s_bisaat.split(":")

        var dt1   = parseInt(event.satis_bitis_tarihi.substring(0,2));
        var mon1  = parseInt(event.satis_bitis_tarihi.substring(3,5));
        var yr1   = parseInt(event.satis_bitis_tarihi.substring(6,10));
        var date1 = new Date(yr1, mon1-1, dt1, timeArr[0], timeArr[1]);
        // return date1;
        let event_timestamp = date1 // new Date(createDateString(event.satis_bitis_tarihi)+'T'+event.s_bisaat+":00");
        let current_stimestamp = null;
        if (timestamp) {
            current_stimestamp = new  Date(timestamp)
            return current_stimestamp < event_timestamp?true:false
        }
   
        return  false
    }

    const handleContinuePurchase = ()=>{
        let user_id = null;

        let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

        if (JSON.parse(localStorage.getItem('isLoggedIn'))) {
            user_id = JSON.parse(localStorage.getItem('isLoggedIn'));
        }

        if (loginVal==='true') {    
            const event_type = EventInfo.etkinlik_sekil;
            
            if (event_type==="artirmali") {
                navigateThere('/artirmali-satis',{"state":{"event_id":EventInfo.id}});
                
            } else {
                navigateThere('/etkinliklar') //pass event id and amout to pay
            }
               

        }else{
            navigateThere('/giris');
        }
        
    }

    useEffect(() => { 
        if (params.eventID) {
            getSingleEvent({"event_id":params.eventID}).then(
            (response)=>{
                if (!response.status) {
                    
                }
                else if (response.status===200 && response.data.data.length>0) {
                    setEventInfo(response.data.data[0])}
                })
        }
     },[])

    
  return (
    <div className="flex items-center mt-9  justify-center w-full text-white">
        <div className="mt-[3rem] bg-green-100 pt-[2rem] text-green-600 w-[20rem] h-[20rem]">
            <p className='font-bold text-[2rem]'>Ödemeniz başarılı!</p>
            <div className='text-gray-700 space-x-2'>
                <button
                onClick={() => { navigateThere("/") }}
                 className='bg-blue-300 rounded-lg px-[0.7rem]'>Anasayfaya git</button>

                <button
                onClick={() => { handleContinuePurchase() }}
                 className='bg-blue-300 rounded-lg px-[0.7rem]'>Alışverişe devam</button>
            </div>
        </div>
    </div>
  )
}

export default PaymentSuccess