import { useState } from "react";
import { formatToTL } from "../helpers";
import { GET_USER_INSTAGRAM, disapproveAttendee, generalGetMethos } from "./requests";
import { useLocation } from "react-router-dom";


const FenomenKatilimciListItem = (props)=>{

    var user_id = '';
    var user_type ='';
    var first_name ='';
    var last_name ='';

    if (JSON.parse(localStorage.getItem('user_id'))) {  
        user_id = JSON.parse(localStorage.getItem('user_id'))
        user_type = JSON.parse(localStorage.getItem('user_type'))
        first_name = JSON.parse(localStorage.getItem('first_name'))
        last_name = JSON.parse(localStorage.getItem('last_name') )
       }

  const [errorMessage, seterrorMessage] = useState('');


    //disapprove an attendee
    function disApprove(uid_) {
        //clear previus error message if any
        seterrorMessage('')
        disapproveAttendee({"user_id":uid_,"event_id":props.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    window.location.reload();
                }
            }
        )
    }

    //open user instagram page
    function showInstagram(user_id)
    {
        generalGetMethos(GET_USER_INSTAGRAM+user_id).then((response) => { 
            if (!response.status) {
                //handle errors
            }else if(response.status===200)
            {
                //redirect to a new window
                window.open("https://www.instagram.com/"+response.data.user_instagram.replace("@",""), "_blank")
            }
         })
    }

// console.log(props.index)
    return(
        <div className={"  border-bottom border-blue "}>
            <table className="w-full">
                <thead>
                        <th>Sıra</th>
                        <th>İsim</th>
                        <th>Tutar</th>
                        <th>Eylemler</th>
                </thead>

                <tbody>
                {props.leaderBoard.map((record, index) => (
                    <tr className={(index+1 >props.maxParticipants?" bg-red-400 ":" ")+" "}>
                        <td className="text-xs py-[0.7rem]">{index+1}</td>
                        <td className="text-xs">
                            
                            {record.user_id != user_id ? record.first_name+' ...':
                            first_name +' '+last_name}
                        </td>
                        <td className="text-xs">
                            {formatToTL(record.total_amount,'database') +' TL'}
                        </td>

                        <td className="flex justify-center space-x-2 py-[0.7rem]">
                        <p
                            className=" cursor-pointer my-0 h-fit text-xs  bg-blue-300 w-[6rem] rounded-md"
                            onClick={() => { showInstagram(record.user_id) }}
                            >Kişi profile</p>

                            <p
                            className=" cursor-pointer my-0 h-fit text-xs bg-blue-300 w-[4rem] rounded-md"
                            onClick={() => { disApprove(record.user_id) }}
                            >Reddet</p>
                        </td>
                    </tr>
                    ))}
                    
                </tbody>
            </table>
           

        </div>



        
    );
}

export default FenomenKatilimciListItem;