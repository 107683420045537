import '@coreui/coreui/dist/css/coreui.min.css';
import { CCard, CCardImage,CCardBody,CCardTitle,CCardText,CButton,CCardHeader,CCollapse,CContainer  } from '@coreui/react';
import { useEffect, useState } from 'react';

// import logo from '../logo.svg';
// import tarkan from '../tarkan.jpg';
import '../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ETKINLIK_IMAGE_URL, getSingleEvent } from './requests';
import { formatToTL } from '../helpers';
// import { CDBCollapse } from 'cdbreact';








const SingleEvent = (props)=> {
    const [eventId, seteventId] = useState('');
    const [visible, setVisible] = useState(false);
    const [EventInfo, setEventInfo] = useState({});
   

    //get eventid from url
    const params = useParams();
    const navigateThere = useNavigate();

    useEffect(() => {
        //   seteventId(params.eventID)
      seteventId(props.event_id)
    }, [])
    

    useEffect(()=>{
        const results = getSingleEvent({"event_id":eventId}).then(
            (response)=>{
                if (!response.status) {
                    
                }
                else if (response.status===200 && response.data.data.length>0) {
                    setEventInfo(response.data.data[0])}
                }
        )
        // setEtkinlikAdi(EventInfo.etkinlik_adi)
        // setEtkinlikUcreti(EventInfo.katilim_fiyati)
        // setEtkinlikKodu(EventInfo.katilim_sayisi)
        // setKisiSayisi(EventInfo.katilim_sayisi)
        // setEtkinlikMekan( EventInfo.il+', '+
        //                 EventInfo.ilce+', '+
        //                 EventInfo.etkinlik_mekan)
                        
        // setetkinliktarihi(EventInfo.etkinlik_tarihi)
        // setSaat(EventInfo.saat)

        
    },[eventId])

    const handleSatinAlClick = ()=>{

        let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

        if (loginVal==='true') {    
            const event_type = EventInfo.etkinlik_sekil;
            
            if (event_type==="artirmali") {
                navigateThere('/artirmali-satis',{"state":{"event_id":EventInfo.id}});
                
            } else if(EventInfo.length>0) {
                
                localStorage.setItem('etkinlikAdi', JSON.stringify(EventInfo.etkinlik_adi));
                localStorage.setItem('etkinlikUcreti', JSON.stringify(EventInfo.katilim_fiyati));
        
                //Later move these into stateful variables
                localStorage.setItem('etkinlikTarihi', JSON.stringify(EventInfo.etkinlik_tarihi));
                localStorage.setItem('etkinlikMekan', JSON.stringify(EventInfo.etkinlik_mekan));
                localStorage.setItem('etkinlikKodu', JSON.stringify(eventId)); //Note: this is the same as ethkinlik ID
                navigateThere('/satin-al');
            }
               

        }else{
            navigateThere('/giris');
        }
        
    }

    return(
    <>
        {EventInfo&&        
        <div className='mt-16 flex justify-center'>                            
            <CCard style={{ width: '20rem',marginBottom:'30px'}}>
            <CCardHeader>
                Etkinlik tarihi: <span className='ms-1'>{EventInfo.etkinlik_tarihi}</span>
            </CCardHeader>
            <div style={{minHeight:'325px',maxHeight:'325px'}}
             className=' d-flex'>
            <CCardImage orientation="bottom" className='align-self-center '
            src={GET_ETKINLIK_IMAGE_URL+EventInfo.event_pic_url} //{EventInfo.profile_pic_uri}
              
              />
              </div>
             {/* style={{maxHeight:'367px'}} /> */}
            <CCardBody>
            <CCardTitle style={{fontSize:'17px',height:'35px'}}>{EventInfo.etkinlik_adi}</CCardTitle>
            <CCardText onClick={() => setVisible(!visible)}>
             {!visible? (<p className='footer-text'>Detayları Gör.</p>):(<p className='footer-text'>Detayları Kapat.</p>)}
            </CCardText>
            
            {/* event details on click */}
                {visible?(<p visible={visible}>
                    {EventInfo.etkinlik_sekil==='sabit'&&
                    <strong>
                        Katılım Ücreti: 
                        <span className='ms-1'>{formatToTL(EventInfo.katilim_fiyati)+' '+'TL' }</span>
                        {/* <span className='ms-1'>TL</span> */}
                    </strong>
                    }
                     <br></br>
                    Katılacak Kişi Sayısı: 
                      <strong> 
                      <span className='ms-1'>{EventInfo.katilim_sayisi}</span>
                      </strong> <br></br>
                    Etkinlik Kodu :<span className='ms-1'>{eventId}</span> <br></br>                    
                    Saat: <span className='ms-1'>{EventInfo.saat}</span> <br></br>
                    {/* Mekan: <span className='ms-1'>{EventInfo.etkinlik_mekan}</span> <br></br> */}
                    
                   
                </p>):(<></>)}

            
                <CButton onClick={handleSatinAlClick} >Satın al</CButton>
                </CCardBody>
            </CCard>

        </div>
        }
    </>
    );
}

export default SingleEvent;