import React, { useState,useRef, useContext  } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { withRouter, useNavigate } from "react-router-dom";
import '@coreui/coreui/dist/css/coreui.min.css';

import "../App.css";
import MyFooter from './footer';
// import { CContainer } from '@coreui/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { verifyUser } from './requests';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
// const LOGIN_URL_PROD = 'http://127.0.0.1:8000/api/login';  //For development
// const AUTH_COOKIE_LINK = 'http://127.0.0.1:8000/sanctum/csrf-cookie'

// const LOGIN_URL_PROD = 'https://api.buradago.com/public/api/login';  //For production
// const AUTH_COOKIE_LINK = 'https://api.buradago.com/public/sanctum/csrf-cookie';



  const HesapDogrulama = () => { 
    //define states
  const [verificationCode, setverificationCode] = useState('');
  
  const [errorMessage, setErrorMessage] = useState('');

  const navigateThere = useNavigate();

  const [success, setSuccess] = useState(false);
  const [showForm, setshowForm] = useState(true);
  const [successMessage, setsuccessMessage] = useState('');

 //get current user id and type
  var user_id = '';
  var user_type ='';
 if (JSON.parse(localStorage.getItem('user_id'))) {  
    user_id =JSON.parse( localStorage.getItem('user_id'))
    user_type = JSON.parse(localStorage.getItem('user_type'))
 }
  

  //on submit function
  const handleSubmit = (e) => {
    e.preventDefault()
    // const user_id = localStorage.getItem('user_id');
        const results = verifyUser({'user_id':user_id, 'verification_code':verificationCode}
          ).then(
            (response)=>{
              // console.log("response",response.response.status)

                if (!response.status) {
                  if (response.response.status!==200) {
                   
                    setErrorMessage(response.response.data.message);
                    setshowForm(false)  
                    setSuccess(false);
                    setsuccessMessage('');
                    
                  }
                  
                }
                else if (response.status===200) {
                    setshowForm(false)
                    setErrorMessage('');
                    setSuccess(true);
                    setsuccessMessage(response.data.message)
                    //update verification status in local storage
                    localStorage.setItem('verified', JSON.stringify("yes"));

                }else if (response.data.status==='failed') {
                    setErrorMessage(response.data.message);
                    setshowForm(false)  
                    setSuccess(false);
                    setsuccessMessage('');
                }
                else{
                  setErrorMessage("Üzgünüz hesap doğrulanması yapılamadık");
                }
            }
        )
  };
  
  //define restart verification button handler
  const onRestartVerification = () => {
    setErrorMessage('');
    setsuccessMessage('');
    navigateThere('/dogrulama');
  }

  //define success component
  const successComp = <div>
  <p className='bg-white text-lime-700'>{successMessage}</p>
  <button className='text-white bg-blue-400 px-2
  rounded-md mb-2' onClick={()=>{navigateThere('/user-admin')}}>
    Hesabıma git
  </button>
  </div>

   //define error component
   const errorComp = <div>
   <p className='bg-danger text-black'>{errorMessage}</p>
   <button onClick={onRestartVerification}>
     Tekrardan Deneyiniz
   </button>
   </div>

   

  return (
    
    <div  className="w-50 h-75  verification-page  mx-auto mt-5">
      {/* <CContainer> */}
        {/* <h2 style={{color:'white'}}>Hesab Doğrulama</h2> <br/> */}
        {showForm&&<div>
          <p className='text-white'>
            Instagram hesabınıza gelen Doğrulama Kodunu aşağıdaki kutuya yazıp, Doğrulama Butonuna basın. 
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" >
              {/* <Form.Label>Kullanici Adı veya mail</Form.Label> */}
              <Form.Control type="text" id="verification_code" className='w-50 mx-auto'
              value={verificationCode}
                onChange={(e) => setverificationCode(e.target.value)}
                placeholder="Doğrulama kodu (öğr: 348579)" />
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Button variant="primary" type="submit">
              Doğrula
            </Button>
          </Form>
        </div>}
    {successMessage&&successComp}
    {errorMessage && errorComp}
    {/* </CContainer> */}
   
    </div>
    
    
    
   
  );
};

export default HesapDogrulama;
