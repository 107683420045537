import React from 'react';
import Table from 'react-bootstrap/Table';
import '../App.css';

const PastEventsTable = ({ data }) => {
  return (
    <Table striped bordered hover className='table'>
      <thead>
        <tr>
          <th>Etkinlik</th>
          <th>Detaylar</th>
          <th>Fiyat</th>
        </tr>
      </thead>
      <tbody>
        {/* {data.map((item, index) => (
          <tr key={index}>
            <td>{item.etkinlinkAdi}</td>
            <td>{item.detaylar}</td>
          </tr>
        ))} */}
        <tr><td></td> <td></td><td></td></tr>
        <tr><td></td> <td></td><td></td></tr>
        <tr><td></td> <td></td><td></td></tr>
        <tr><td></td> <td></td><td></td></tr>
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}

      </tbody>
    </Table>
  );
};

export default PastEventsTable;
