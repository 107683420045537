import { RiCloseLargeFill } from "react-icons/ri";
import { FaX } from "react-icons/fa6";
import React, { useEffect } from 'react'
import { closeTicketView } from "./item-imports";
import { GET_QRCODE_URL } from "../requests";

const ViewTicket = ({ticket,setshowViewTicket}) => {

useEffect(() => {
  console.log(GET_QRCODE_URL+ticket.qrcodeuri);

  
}, [])


  return (
    <div className='w-full h-[90%] absolute mt-[2rem]  '>
      <div
       className="text-white z-30 w-full   flex justify-center">
          
          <div className=" w-full lg:w-[25rem] h-[25rem] rounded-md bg-gray-200">
            {/* close button */}
            <div className="w-full flex justify-end">
              <img
              className="cursor-pointer w-[2rem] p-1 m-1"
              onClick={() => { setshowViewTicket(false) }}
              src={closeTicketView} alt="" />
            </div>

            {/* ticket qrcode image */}
            <img src={GET_QRCODE_URL+ticket.qrcodeuri} alt="qrcode" />
          </div>

        </div>
    </div>
  )
}

export default ViewTicket