import React, { useEffect } from 'react';
// import Table from 'react-bootstrap/Table';
import '../App.css';
import axios from 'axios';
import { useState } from 'react';
import { getSingleEvent } from './requests';
import { formatToTL } from '../helpers';
// import {Glyphicon} from 'react-bootstrap';



const EtkinlikBilgiTable = (props) => {

  
    const [etkinlikAdi, setEtkinlikAdi] = useState('');
    const [etkinlikTarihi, setEtkinlikTarihi] = useState('');
    const [minKatilimTutari, setMinKatilimTutari] = useState('');
    const [minArtirmaTutari, setMinArtirmaTutari] = useState('');
    const [maxKatilimciSayisi, setMaxKatilimciSayisi] = useState('');
    const [maxOdenenTutari, setMaxOdenenTutari] = useState('');
    // const [minOdenenTutari, setMinOdenenTutari] = useState('');
    const [baslangicFiyati, setbaslangicFiyati] = useState('');
    const [kalanSure, setKalanSure] = useState('');

    const getEventInfo = async ()=>{
        const results = getSingleEvent({"event_id":props.event_id}).then(
            (response) => {
                if (!response.status) {
                    
                } else if (response.status===200 &&response.data.data.length>0) {
                    const event_ = response.data.data[0];
                    setEtkinlikAdi(event_.etkinlik_adi)
                    setEtkinlikTarihi(event_.etkinlik_tarihi)
                    setMinArtirmaTutari(event_.minimum_art_tutari)
                    setMaxKatilimciSayisi(event_.katilim_sayisi)      
                    setbaslangicFiyati( event_.baslangic_fiyati)
                    setMaxOdenenTutari(response.data.max_payment);
                }
                
            }
        )

        //   setKalanSure(response?.data.created_at)
  }

  //put a useEffect here and call the fetchEtkinlik() function
  useEffect(() => {
    getEventInfo();
        
    }
  , []);

  return (
    <table className="bg-white w-100 text-start  px-0" style={{borderRadius:'10px',overflow:'hidden'}}>
                                <tbody style={{fontSize:"0.7rem"}}>
                                 
                                <tr >
                                        <td>
                                            <strong>
                                                <span className="  glyphicon glyphicon-bookmark text-primary"></span>
                                                Başlangıç Fiyatı : 
                                            </strong>
                                        </td>
                                        <td className="text-primary">
                                        {baslangicFiyati&&formatToTL(baslangicFiyati.toString()) } TL
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <strong>
                                                <span className="glyphicon glyphicon-bookmark text-primary"></span>
                                                Min. Artırma Tutarı : 
                                            </strong>
                                        </td>
                                        <td className="text-primary">
                                        {minArtirmaTutari&&formatToTL(minArtirmaTutari.toString())} TL
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <strong>
                                                <span className="glyphicon glyphicon-eye-open text-primary"></span>
                                                Max. Katılımcı Sayısı :
                                            </strong>
                                        </td>
                                        <td className="text-primary">
                                        {maxKatilimciSayisi} Kişi
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span className="glyphicon glyphicon-envelope text-primary"></span>
                                                Max. Ödenen Tutarı :
                                            </strong>
                                        </td>
                                        <td className="text-primary">
                                        {maxOdenenTutari&&formatToTL(maxOdenenTutari.toString(),'database')} TL
                                        </td>
                                    </tr>
                                 
                                </tbody>
                            </table>
  );
};

export default EtkinlikBilgiTable;
