import { useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PAYMENT_DATA_POST_URL } from "./requests";

// const USER_INFO_URL = 'http://127.0.0.1:8000/api/user';  //For development
// const PAYMENT_DATA_POST_URL = 'http://127.0.0.1:8000/api/prepayment-post';  //For development


const USER_INFO_URL = 'https://api.buradago.com/public/api/user';  //For production






const Cart = ()=>{

  const [fatura_address, setFaturaAddress] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [phone, setPhone] = useState('');

  //Pul the following data from either local storage or navigate there 
  //mechanism thrown from the home page when satin al clicked
  const [etkinlikAdi, setEtkinlikAdi] = useState('');
  const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
  const [etkinlikTarihi, setEtkinlikTarihi] = useState('');
  const [etkinlikMekan, setEtkinlikMekan] = useState('');

  const [event_id, setEvent_id] = useState('');
  const [user_id, setUser_id] = useState('');
  // const [ticket_id, setTicket_id] = useState('');
  const [user_email, SetUser_email] = useState('');


  const [visible, setVisible] = useState(false);


  const navigateThere = useNavigate();
  


const fetchUserInfo = async ()=> {

  // try {
  //   const resUserInfo = await axios.get(USER_INFO_URL,
  //     // JSON.stringify({ user_email,password }),
  //     {
  //         headers: { 'Content-Type': 'application/json',
  //         'Accept':'application/json' },
  //         withCredentials: true
  //     }
  // );
  // console.log(resUserInfo?.data)
  // if (resUserInfo?.data) {
  //   var parsedUInfo = JSON.parse(resUserInfo.data.user);
  //   setFirst_name(parsedUInfo.first_name);
  //   setLast_name(parsedUInfo.last_name);
  //   setPhone(parsedUInfo.phone);
  //   // console.log(JSON.parse(resUserInfo.data.user).first_name)
  //   console.log(first_name)
  //   console.log('from response ',parsedUInfo.first_name )
    
  // }
  // } catch (error) {
    
  // }

  //retrieve and fill up etkinlik info
  let etkinlik_name = JSON.parse(localStorage.getItem('etkinlikAdi'));
  let etkinlik_price = JSON.parse(localStorage.getItem('etkinlikUcreti'));

  let etkinlik_date = JSON.parse(localStorage.getItem('etkinlikTarihi'));
  let etkinlik_venue = JSON.parse(localStorage.getItem('etkinlikMekan'));



  let userName = JSON.parse(localStorage.getItem('first_name'));
  let userLastName = JSON.parse(localStorage.getItem('last_name'));
  let userPhone = JSON.parse(localStorage.getItem('phone'));

  let email_ = JSON.parse(localStorage.getItem('email'));
  let user_id_ = JSON.parse(localStorage.getItem('user_id'));
  let event_code_ = JSON.parse(localStorage.getItem('etkinlikKodu'));
  

  

  setFirst_name(userName);
  setLast_name(userLastName);
  setPhone(userPhone);
  setUser_id(user_id_)
  SetUser_email(email_)

  setEtkinlikAdi(etkinlik_name);
  setEtkinlikUcreti(etkinlik_price);
  setEvent_id(event_code_);

  setEtkinlikTarihi(etkinlik_date);
  setEtkinlikMekan(etkinlik_venue);

}

//Create prepayment entry
const  PostPrepaymentData= async ()=> {
    try {
    const response = await axios.post(PAYMENT_DATA_POST_URL,
        JSON.stringify({first_name,last_name,user_email,event_id,user_id,
          fatura_address,phone, etkinlikUcreti}),
        {
            headers: { 'Content-Type': 'application/json',
            'Accept':'application/json' },
            withCredentials: true
        }
    );

    if (response?.data.order_id){ 
      localStorage.setItem('order_id', JSON.stringify(response?.data.order_id));
      navigateThere('/odeme-yap');
    // console.log(response?.data);
  }
    
} catch (err) {
    if (!err?.response) {
      console.log('There was an error');
      // setErrorMessage('No Server Response');
    } 
    }
  
}

const handlePay = async (e) =>{ 

  e.preventDefault();
  // console.log(JSON.stringify({first_name,last_name,user_email,event_id,user_id,
  //   fatura_address,phone, etkinlikUcreti}))
  PostPrepaymentData();
  

}

useEffect(()=>{ 
  //   let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));
  // if(loginVal=='true'){setIsLoggedin('true')};  

  //Get current user info
  fetchUserInfo();
   


  },);




    return(
      <div className="satin-all-page" style={{color:'white'}}>

        

        <form onSubmit={handlePay}>
              <div class="mb-3 satin-al-inner-block" >
                <h5><u>Etkinlik Adı</u></h5>
                <p style={{margin:'10px'}}>
                  <p>{etkinlikAdi}</p>
                  <div onClick={() => setVisible(!visible)}>
                  {!visible? (<p className='satin-al-details'>Detayları Gör.</p>):(<p className='satin-al-details'>Detayları Kapat.</p>)}
                  </div>
                  {visible?(<p visible={visible}>
                    Etkinlik Tarihi: <span style={{marginLeft:'10px', fontStyle:'italic',color:'#65c7e0'}}>{etkinlikTarihi}</span><br></br>
                    Mekan: <span style={{marginLeft:'10px',fontStyle:'italic',color:'#65c7e0'}}>{etkinlikMekan}</span> <br></br>
                    
                   
                </p>):(<></>)}
                  <hr/>
                  Sipariş Toplamı: <strong><span style={{marginLeft:'10px', color:'#65c7e0'}}>{etkinlikUcreti}</span></strong>
                   <br/><span style={{fontSize:'12px'}}>(%20 kdv dahil)</span>
                  <hr/>
                  <div style={{textAlign:"left", fontSize:'15px'}}>
                    <p>Ad : <span style={{marginLeft:'10px', color:'#65c7e0'}}>{first_name}</span></p>
                    <p>Soyad : <span style={{marginLeft:'10px', color:'#65c7e0'}}>{last_name}</span></p>
                    <p>Tel:  <span style={{marginLeft:'10px',color:'#65c7e0'}}>{ phone}</span></p>
                    <textarea className="w-[80%] bg-white"
                     placeholder="Adres" required value={fatura_address} 
                     onInput={(event)=>{setFaturaAddress(event.target.value)}}>

                     </textarea>
                    {/* <p>(il, ilçe, Mah., Sk., No.)</p> */}
                  </div>

                  </p>

                <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" required/>
                <label class="form-check-label" htmlForfor="exampleCheck1">Sözleşmeyi Okudum</label>
              </div>
                
              </div>

              <button type="submit" class="btn btn-success">Ödeme Yap</button>
          </form>
      </div>
    );
}

export default Cart;