import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { approveAttendee, disapproveAttendee, getAttendanceList, getEventOwnerAttendees } from "../requests";



const EventAttendees = () => {
    const navigateThere = useNavigate()
    const [events, setevents] = useState([]);
    const [attendants, setattendants] = useState( [ ]);
    const [errorMessage, seterrorMessage] = useState();
    const [toggleLoadAttendees, settoggleLoadAttendees] = useState(true);

    //sample attendants list
//     {   "id":1,
//     "user_id": 18,
//     "first_name": "Rashid",
//     "last_name": "Mukaila",
//     "checked_in": null
// },
// {   "id":2,
//     "user_id": 18,
//     "first_name": "Rashid",
//     "last_name": "Mukaila",
//     "checked_in": 1
// }

    const location = useLocation();
    const eventInfo = location.state || {"event_id":-1033};

    //attendee statuses
//    const onayComp = <p className="bg-lime-300 text-xs ">Onaylanmış</p>
//    const toOnayComp = <p className="bg-yellow-300 text-xs ">Onaylanmamış</p>
//    const redComp = <p className="bg-red-500 text-xs ">Rededilmış</p>

    //reject attendant function
    function disApprove(orderInfo) {
        //clear previus error message if any
        seterrorMessage('')
        disapproveAttendee({"user_id":orderInfo.user_id,"event_id":orderInfo.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    settoggleLoadAttendees(!toggleLoadAttendees);
                }
            }
        )
    }

    //reapprove/approve attendant
    function approve(orderInfo) {
        //clear previus error message if any
        seterrorMessage('')
        // "
        approveAttendee({"user_id":orderInfo.user_id,"event_id":orderInfo.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz onaylama! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    settoggleLoadAttendees(!toggleLoadAttendees);
                }
            }
        )
    }

    useEffect(() => {
        // 
        getEventOwnerAttendees({"event_id":eventInfo.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("katılımcı listesi alınamadı")
                } else if (response.status===200) {
                    setattendants(response.data.attendees)
                }
            }
        )
    
    }, [toggleLoadAttendees]);

    
    return(
        <div className="px-4 mt-20">
            
            <div className="w-full overflow-y-auto h-96 items-start
             flex mt-8 border rounded-md">
                <table className="w-full text-sm border-3 bg-gray-300
                 ">
                    <thead className="w-full space-x-3 text-xs">
                        <tr>
                            <th>ID</th>
                            <th>Ad Soyad</th>
                            <th>Inst Photo</th>
                            <th>Instagram</th>
                            <th>Eylemler</th>
                        </tr>

                        {/* <th></th> */}
                    </thead>

                    <tbody className="text-xs text-black">
                        {attendants&&
                            attendants.map((attndnt, index) => (
                                <tr key={index}className="bg-white">
                                    <td className="pb-4">{attndnt.user_id}</td>
                                    <td className="pb-4">{attndnt.first_name +" "+attndnt.last_name}</td>
                                    <td className="pb-4">
                                         <img className="w-10 h-10 rounded-full"
                                         src={attndnt.profile_pic_url} alt="Users instagram profile" />
                                    </td>
                                    <td>
                                        {/* users instagram name with at sign */}
                                       <a target="_blank"
                                       href= {"https://www.instagram.com/"+attndnt.instagram}
                                       content="link to user instagram">
                                        
                                        {attndnt.instagram}
                                       </a>
                                    </td>

                                    <td className="pb-4">
                                        {/* create redet button if attendant is already 
                                        in event or yet to enter event */}
                                        {attndnt.event_owner_approved==='approved'||attndnt.event_owner_approved===null?
                                         <button
                                         className="bg-red-400  rounded-md text-xs px-2
                                         text-black"
                                          onClick={() => { 
                                            disApprove(attndnt)
                                           }}> {/**the id is order id */}
                                            Redet
                                        </button>
                                        : //if user has already been disapproved mistakenly or is yet to be approved by event owner
                                            attndnt.event_owner_approved==='disapproved'||attndnt.event_owner_approved===null?
                                                <button
                                                    className="bg-lime-300  rounded-md text-xs px-2
                                                    text-black"
                                                    onClick={() => { 
                                                    approve(attndnt)
                                                    }}> {/**the id is order id */}
                                                    Onayla
                                                </button>
                                            ://if non of the condition in parent and nested condition blocks fails return nothing
                                                 <></>
                                         }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
export default EventAttendees;