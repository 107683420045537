// import logo from '../logo.svg';

import { useContext, useState, useEffect } from "react";
import {Route,Switch,BrowserRouter, Routes, Outlet} from "react-router-dom";
import LoginProvider from "./components/context-provider";
import './App.css';
import './input.css';
// import HeaderPc from './components/header-pc';
import Home from './components/pages/home-page';
// import HeaderMob from './components/header-mob';
import Header from './components/header';
import Footer from './components/footer';
import Layout from "./components/layout";
import NoPage from "./pages/noPage";
import LoginPage from "./pages/login-page";
import UyeOlPage from "./pages/uye-ol-page";
import User from "./components/pages/user-admin";
import UserAdminPage from "./components/pages/user-admin2";
// import ViewPdf from './components/view-pdf';
import FooterPage from "./pages/footer-element-page";
import FooterPage1 from "./pages/footer-element-page1";
import FooterPage2 from "./pages/footer-element-page2";
import FooterPage3 from "./pages/footer-element-page3";
import SatinAlPage from "./pages/satin-al";
import FenomenAdmin from "./pages/fenomen-admin";
import FenEtkinlikGor from "./pages/fenomen-etkinlik-goruntuleme";
import MakePayment from "./pages/odeme-sayfasi";
import ArtirmaliSatisSayfasi from "./pages/artirmali-satis-sayfa";
import BasicExample from "./test-navbar";
import HesapDogrulama from "./components/hesap-dogrulama-c";
import DogrulamaPage from "./pages/dogrulama-page";
import ArtirmaliSatinAlPage from "./pages/artirmali-satinal-sayfasi";
import TestSse from "./test-sse";
import MakeBiddedPayment from "./pages/artirmali-odeme-sayfasi";
import DevComp from "./components/dev-mode";
import Events from "./components/pages/events";
import EventAttendees from "./components/fenomen/event-attendees";
import SingleEvent from "./components/single-event";
import KesilecekFatura from "./components/fenomen/kesilecek-fatura";
import EventRequest from "./components/fenomen/etkinlik-taleb";
import Addresslerim from "./components/hesabim/addresslerim";
import PaymentSuccess from "./components/payments/payment-success";
import PaymentFailure from "./components/payments/payment-failed";
import PasswordResetEmail from "./pages/pass-reset-email";
import PasswordResetScreen from "./pages/pass-reset-screen";
import CreditCardForm from "./pages/payment-card-details";
import MyTickets from "./components/hesabim/my-tickets";
import DynamicComponent from "./components/dynamicComponent";
import EventOwnerAdminMessage from "./pages/event-owner.jsx/message-to-admin";




function App() {

  const [isLoggedin, setIsLoggedin] = useState('false');


  //Check if loggedin


  useEffect(()=>{
    let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

  if(loginVal=='true'){setIsLoggedin('true')};  

  });

  

  return (
    
    <div className="App">

    <BrowserRouter>

    
        <Routes>
          
        <Route path="/" element={
          // <LoginProvider value={{isLogin,setIsLogin}}>
        <Layout />
        // </LoginProvider>
        
        }>
          {/* <Route path="/sse" element={<div className="mx-5 w-25 h-h-50 "><TestSse/></div>} /> */}
          {/* dev route */}
          {/* <Route path="/dev_mode" element={<DevComp/>} /> */}
           <Route path="dev_mode" element={<DevComp/>}/>
          <Route path="/dogrulama" element={<DogrulamaPage />} />

          {/* real routes */}
        <Route index element={<Home />} />
        <Route path="/events" element={<Events />} />
        {/* <Route path="/:userfullname" element={<Events />} /> */}
        {/* below is for a single event */}
        {/* <Route path="/:eventID" element={<SingleEvent />} /> */}
        {/* component for displaying as event owners all events or a single event */}
        <Route path="/:urlParam" element={<DynamicComponent />} />
        <Route path="/giris" element={<LoginPage />} />
        <Route path="/uye-ol-page" element={<UyeOlPage />} />
        <Route path="/user-admin" element={<UserAdminPage />} />                
        {/* <Route path="/view-pdf" element={<ViewPdf />} /> */}
        {/* Site kullanim */}
        <Route path="/footer-element-page" element={<FooterPage />} /> 
        
        {/* KVKK */}
        <Route path="/footer-element-page1" element={<FooterPage1 />} />

        {/* CEREZ POLITIKASI */}
        <Route path="/footer-element-page2" element={<FooterPage2 />} />

        {/* "iletisim" */}
        <Route path="/footer-element-page3" element={<FooterPage3 />} />

        <Route path="/satin-al" element={<SatinAlPage />} />
        <Route path="/artirmali-satinal-sayfasi" element={<ArtirmaliSatinAlPage />} />

        <Route path="odeme-yap" element={<MakePayment />} />
        <Route path="artirmali-odeme-yap" element={<MakeBiddedPayment />} />
        <Route path="/artirmali-satis" element={<ArtirmaliSatisSayfasi />} />

        {/* FENOMEN/EVENT OWNER */}
        <Route path="/event-attendees" element={<EventAttendees />} /> 
        <Route path="/fenomen-admin" element={<FenomenAdmin />} />
        <Route path="/fenomen-etkinlik" element={<FenEtkinlikGor />} />
        <Route path="/kesilecek-fatura" element={<KesilecekFatura />} />
        <Route path="test-navbar" element={<BasicExample />} />
        <Route path="event-request" element={<EventRequest />} />
        <Route path="message-admin" element={<EventOwnerAdminMessage />} />
        <Route path="*" element={<NoPage />} />

        {/* Ordinary user */}my-tickets
        <Route path="my-addresses" element={<Addresslerim />} />
        <Route path="my-tickets" element={<MyTickets />} />

        {/* Payment related  */}
        <Route path="payment-success/:eventID" element={<PaymentSuccess />} />
        <Route path="payment-failure/:eventID" element={<PaymentFailure />} />
        <Route path="card-details" element={<CreditCardForm />} />

        {/* Password stuff */}
        <Route path="password-reset-email" element={<PasswordResetEmail />} />
        <Route path="password-reset/:resetToken" element={<PasswordResetScreen />} />

        {/* FOr tests */}
        {/* <Route path="devcomp" element={< />} /> */}        

      </Route>
      
      </Routes>
        
      </BrowserRouter>
  
    </div>
    
  );
}

export default App;
