import { useEffect, useState } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CContainer,CRow,CCol, CHeaderDivider, CFormLabel  } from '@coreui/react';
import PastEventsTable from './user-past-event';
import axios from 'axios';
import user_avatar from "../user_avatar.jpg"
import MyFooter from './footer';
import FenomenEtkinlikTable from './fenomen/fenomen-etkinlik-table';
import { GET_EVENT_OWNER_COMMENTS, GET_EVENT_OWNER_RELATED_COMMENTS, generalGetMethos } from './requests';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';


const USER_INFO_URL = 'http://127.0.0.1:8000/api/user';  //For development
// const USER_INFO_URL = 'https://api.buradago.com/public/api/user';  //For production





const Fenomen = ()=>{
    const navigateThere = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nick, setNick] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [instagram, setInstagram] = useState('');
    const [dateCreated, setDateCreated] = useState('');
    const [comments, setcomments] = useState([])

    const fetchUserInfo = async ()=>{


        try {
            const response = await axios.get(USER_INFO_URL,
            // JSON.stringify({ email,password }),
            {
                headers: { 'Content-Type': 'application/json',
                'Accept':'application/json' },
                withCredentials: true
            }
             );
            setFirstName(response?.data.first_name)
            setLastName(response?.data.last_name)
            setNick(response?.data.nick)
            setEmail(response?.data.email)
            setPhone(response?.data.phone)            
            setInstagram(response?.data.instagram)
            setDateCreated(response?.data.created_at)
            
            // console.log("PasedUserInfo object",parsedUser)
            // console.log("FirstName state is: ",firstName)


            // }
            //  console.log(response?.data);
    
        } catch (err) {
                if (!err?.response) {
                console.log('There was an error');
                // setErrorMessage('No Server Response');
                      } 

                }
    }

    //fetch event owner related comments
    function fetchComments() {
        generalGetMethos(GET_EVENT_OWNER_RELATED_COMMENTS).then(
            (response) => { 
                if (!response.status) {
                    //dialog to say comment not fetched
                } else if(response.status===200){
                    setcomments(response.data.comments)
                }
             }
        ).catch()
    }

    useEffect(
        ()=>{fetchUserInfo()
            fetchComments()
        }
    ,[]);

    return (
        
        <div className="homebody">
            <div className="panel-body inf-content">
                

                <div className='row justify-content-center'>


                    <CRow className='row justify-content-center'>
                        <div className="row justify-content-center" style={{ marginTop: '30px' }}>


                            <CCol sm ={6}><h3 style={{ color: 'white' }}>Etkinliklerim</h3>
                                <FenomenEtkinlikTable />

                            </CCol>
                            <CCol sm ={6}>
                                <CRow>
                                    <h3 style={{ color: 'white' }}>Kullanıcı Yorumları</h3>
                                </CRow>
                                <CRow>
                                    {/* <CCol className='.2'>Etkinlik</CCol> */}
                                    <CCol className="overflow-y-auto h-[25rem]">

                                        {comments&&
                                        comments.map((comment) => (
                                            <div key={comment.id}>
                                                <p className="w-full mb-0 text-xs text-white text-end">
                                                    {format(Date(comment.created_at),"dd-MM-yyyy HH:mm")}
                                                </p>
                                                <p className='feno-yorumlarim text-sm' >
                                                    {comment.text}
                                                </p>
                                            </div>

                                        ))}
                                        {/* <p className='feno-yorumlarim'> */}
                                            {/* Katıldığım etkinlik harikaydı! Etkinlik adı ile Detaylar
                                                bölümleri oldukça ilgi çekiciydi ve organizasyon mükemmel
                                                şekilde düzenlenmişti. Tek kelimeyle harika bir deneyimdi, tekrar katılmak isterim! */}
                                        {/* </p> */}
                                    </CCol>
                                </CRow>
                                <CRow></CRow>
                                <CRow></CRow>

                            </CCol>
                            {/* <CCol><Etkinlik reactImage={tarkan}/></CCol> */}

                        </div>

                        {/* <Etkinlik /> */}
                    </CRow>


                </div>
            </div>
        </div>
       
    );
}

export default Fenomen;