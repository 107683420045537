import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";

import { useRef, useState } from 'react';
import { withRouter, useNavigate } from "react-router-dom";
import { AUTH_COOKIE_LINK, REGISTER_URL_PROD } from './requests';
import ErrorComp from './error-component';



const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
// const REGISTER_URL_PROD = 'http://127.0.0.1:8000/api/register';  //For dev
// const REGISTER_URL_PROD = 'https://api.buradago.com/public/api/register';  //For production




function UyeOlForm() {
  const navigateThere = useNavigate()

  const userRef = useRef();
  const errRef = useRef();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nick, setNick] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [instagram, setInstagram] = useState('');
  const [pwd, setPwd] = useState('');
  const [address, setAddress] = useState('');

  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState([]);
  const [success, setSuccess] = useState(false);

  


  const handleSubmit  = async (e) => {
    e.preventDefault();
    setErrMsg([])
    
    // if button enabled with JS hack
    // const v1 = USER_REGEX.test(name);
    // const v2 = PWD_REGEX.test(pwd);
    // if (!v1 || !v2) {
    //   console.log('There was an error');
    //     setErrMsg("Invalid Entry");
    //     return;
    // }
      //first get csrf
        await axios.get(AUTH_COOKIE_LINK)
        const results = axios.post(REGISTER_URL_PROD,
            JSON.stringify({ firstName,lastName,nick,email,phone,instagram,pwd }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        ).then(
          (response) => { 
            if (!response.status) {
              console.log('There was an error');
              setErrMsg('No Server Response');
              
            } else if (response.status===200) {
              
              localStorage.setItem('isLoggedIn', JSON.stringify('true'));
      
              // console.log(response?.data.user)
              var parsedUser = JSON.parse(response.data.user)
              // var parsedUser = response.data.user
      
      
              //This will be needed for the satin-al page
              localStorage.setItem('first_name', JSON.stringify(parsedUser.first_name));
              localStorage.setItem('last_name', JSON.stringify(parsedUser.last_name));
              localStorage.setItem('phone', JSON.stringify(parsedUser.phone));
              localStorage.setItem('user_type', JSON.stringify(parsedUser.user_type));
              localStorage.setItem('user_id', JSON.stringify(parsedUser.id));
              localStorage.setItem('email', JSON.stringify(parsedUser.email));
              
              navigateThere('/dogrulama')
            }else {
              console.log('There was an error');
              setErrMsg('No Server Response');
            }
           }
           
        ).catch(
          (error) => { 
            setErrMsg(Object.values(error.response.data.errors));
            // console.log(Object.values(error.response.data.errors));
           }
        );
       
        // errRef.current.focus();
    }


  


  return (
    <div>
      <h3 style={{color:'white'}}>Üye Ol</h3>
      {errMsg&&
      <ErrorComp errors={errMsg}/>}
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3"  controlId="formBasicEmail">
        {/* <Form.Label>Email address</Form.Label> */}
        <Form.Control type="text" name='firstName' placeholder="İsim" required
        value={firstName}
         onChange={(e) => setFirstName(e.target.value)}/>
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="text" name='lastName' placeholder="Soyisim" required 
        value={lastName}        
        onChange={(e) => setLastName(e.target.value)}/>
       
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="text" name='nick' placeholder="Nick/kullanıcı adı" required
        value={nick}
         onChange={(e) => setNick(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="email" name='email' placeholder="Mail" required
      value={email}
      onChange={(e) => setEmail(e.target.value)}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="password" name='password' placeholder="Şifre" required 
      value={pwd}
      onChange={(e) => setPwd(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword"> 
      {/* pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" */}
      <Form.Control type="tel" name="phone"  placeholder="Tel No." required
      value={phone}
       onChange={(e) => setPhone(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="text" name='instagram' placeholder="İnstagram: @example12" required
      value={instagram}
       onChange={(e) => setInstagram(e.target.value)} />
      </Form.Group>


      <Button variant="primary" type="submit" >
        Üye Ol
      </Button>
    </Form>
    </div>
  );
}

export default UyeOlForm;