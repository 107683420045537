import { BsQrCode } from "react-icons/bs";
import React, { useEffect, useState } from 'react'
import { GET_USER_TICKETS, generalGetMethos } from '../requests';
import { qrIcon } from "./item-imports";
import ViewTicket from "./view-ticket";

const MyTickets = () => {

    const [tickets, settickets] = useState([]);
    const [errMessage, seterrMessage] = useState("");
    const [showViewTicket, setshowViewTicket] = useState(false);
    const [clickedTicket, setclickedTicket] = useState({});

    const handleViewTicket = (ticket_) => { 
        setclickedTicket(ticket_);;
        setshowViewTicket(true);
     }

    useEffect(() => { 
        generalGetMethos(GET_USER_TICKETS).then((response) => { 
            if (response.status===200) {
                seterrMessage("");
                settickets(response.data.tickets)
                
            } else {
                seterrMessage("Beklenmeyen bir hata oluşmuş!")                
            }
         })
     },[])

  return (
    <div className="mt-[3rem] text-white w-full ">
        {errMessage&&<p className='bg-red-400'>{errMessage}</p>}
        {showViewTicket&& <ViewTicket ticket={clickedTicket}
             setshowViewTicket={setshowViewTicket}/>}
        <h1>Biletlerim</h1>
        <div className='w-full flex justify-center overflow-y-auto'>
            <div
            className=" rounded-md  bg-gray-600 h-[20rem] grid lg:grid-cols-5
                        grid-cols-4 lg:h-[20rem] lg:w-[40%] w-full p-[1rem]"
            >      
            {tickets && tickets.map((ticket,index) => (
                <div className="w-[4.5rem] flex-col">
                    <img
                    className="cursor-pointer w-[4rem]"
                    onClick={() => {handleViewTicket(ticket)}}
                    src={qrIcon} alt="" />
                    <p className="text-xs m-0 p-0 w-full justify-center flex">
                        Etkinlik Tarihi <br />
                        {ticket.etkinlik_tarihi}
                    </p>
                </div>
            ))}      
            
             
             
            </div>
        </div>
    </div>
  )
}

export default MyTickets