import { useEffect, useState } from "react";
import "../../App.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CitiesJson from "../cities";
import { getCurrentUser, postPrepaymentData } from "../requests";
import IndividualAddress from "./individual-address";
import OrganizationalAddress from "./organizational-address";



const cities = Object.keys(CitiesJson);

const DisplayBillingAddress = (props) => {

  //address types
  const ind_add_type = 'individual';
  const org_add_type = 'organizational';
  // const location = useLocation();
  // const paymentInfo = location.state;

  // //city selection
  // const [province, setProvince] = useState('');
  // const [district, setDistrict] = useState('');
  // const [relatedDistricts, setRelatedDistricts] = useState([])

  // const [fatura_address, setFaturaAddress] = useState('');
  // const [full_name, setFullName] = useState('');
  // // const [last_name, setLast_name] = useState('');
  // const [phone, setPhone] = useState('');
  // const [address_type, setaddressType] = useState(ind_add_type);

  //Pul the following data from either local storage or navigate there 
  //mechanism thrown from the home page when satin al clicked
  // const [etkinlikAdi, setEtkinlikAdi] = useState('');
  // const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
  // const [etkinlikTarihi, setEtkinlikTarihi] = useState('');
  // const [etkinlikMekan, setEtkinlikMekan] = useState('');
  // const [odemeTutari, setodemeTutari] = useState();
  // const [country, setCountryLabel] = useState('Turkey'); //country name
  // const [address_type, setaddressType] = useState(ind_add_type); //or organization
  // const [tcNo, settcNo] = useState('');
  // const [is_turkish, setIsTurkish] = useState(true);
  // const [tel, setTel] = useState();

  // //organizational address stuff
  // const [address_name, setaddressName] = useState(null);
  // const [vkn, setvkn] = useState('');
  // const [vergi_dairesi, setvergiDairesi] = useState('');
  // const [organisation_name, setfirmaAdi] = useState('');

  // const [event_id, setEvent_id] = useState('');
  // const [user_id, setUser_id] = useState('');
  // // const [ticket_id, setTicket_id] = useState('');
  // const [user_email, SetUser_email] = useState('');


  // const [visible, setVisible] = useState(false);


  // const navigateThere = useNavigate();
  


// const fetchEventInfo = ()=> {

  //retrieve and fill up etkinlik info
  // let etkinlik_name = JSON.parse(localStorage.getItem('etkinlikAdi'));
  // let etkinlik_price = JSON.parse(localStorage.getItem('etkinlikUcreti'));

  // let etkinlik_date = JSON.parse(localStorage.getItem('etkinlikTarihi'));
  // let etkinlik_venue = JSON.parse(localStorage.getItem('etkinlikMekan'));



  // let userName = JSON.parse(localStorage.getItem('full_name'));
  // let userLastName = JSON.parse(localStorage.getItem('last_name'));
  // let userPhone = JSON.parse(localStorage.getItem('phone'));

  // let email_ = JSON.parse(localStorage.getItem('email'));
  // let event_code_ = JSON.parse(localStorage.getItem('etkinlikKodu'));
  // const event_info = paymentInfo.event_info[0];
  // setEtkinlikAdi(event_info.etkinlik_adi);
  // setodemeTutari(paymentInfo.payment_amount);
  // setEvent_id(event_info.id);
  // setEtkinlikTarihi(event_info.etkinlik_tarihi);
  // setEtkinlikMekan(event_info.etkinlik_mekan);
  
// }

//get current user
// const getUserInfo = () => {
//   let user_id_ = JSON.parse(localStorage.getItem('user_id'));
//   const results = getCurrentUser().then((response)=>{
//     if (response.status===200) {
//         const user_info = response.data.user;
//         //update user info states
//         // var parsedUser = JSON.parse(response?.data.user)
//         setFullName(user_info.full_name)
//         // setLast_name(user_info.last_name)
//         SetUser_email(user_info.email)
//         setPhone(user_info.phone)   
//         setUser_id(user_id_) 
        
//     }

//     return response;
// }).catch((errors)=>{return errors;});
// }



// useEffect(()=>{ 
  //   let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));
  // if(loginVal=='true'){setIsLoggedin('true')};  

  //Get current user info
  // getUserInfo();

  //get event info
  // fetchEventInfo();
   


  // },);



  return (
    <div className="w-full h-full">
      {props.props.address.address_type===ind_add_type?
        <IndividualAddress 
            address={props.props.address} onEditAddress={props.props.onEditAddress}/>
        :
        <OrganizationalAddress
             address={props.props.address} onEditAddress={props.props.onEditAddress}/>
      }
    </div>
  )
}

export default DisplayBillingAddress