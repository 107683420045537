import MyFooter from "../components/footer";
import "../App.css";
import ArtirmaliSatinAlCart from "../components/artirmali-satin-al-cart";




const ArtirmaliSatinAlPage = () =>{
    
    return(

        <div >
            {/* <h5 style={{color:"white"}}>Etkinlik Ödeme Sayfası</h5> */}

            <div >
                <ArtirmaliSatinAlCart/>
            </div>
            <MyFooter/>
        </div>
    );

}

export default ArtirmaliSatinAlPage;