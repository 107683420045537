// import logo from '../../logo.svg';
import '../../App.css';

import '@coreui/coreui/dist/css/coreui.min.css';
import { CContainer,CRow,CCol  } from '@coreui/react';

import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';

import Etkinlik from '../etkinlik-card';
import Etkinlik2 from '../etkinlik-card2';
import logo from '../../logo.svg';
import tarkan from '../../tarkan.jpg';
import rashid from '../../rashid.jpg';
import fatih2 from '../../fatih2.jpeg';
import olcay from '../../olcay.jpeg';
import Footer from '../footer';
import { getAllEvents } from '../requests';
import EtkinlikGeneral from '../etkinlikGeneral-card';
import { Carousel } from 'flowbite-react';
import { Link } from 'react-router-dom';
import homePagePicGreen from '../../assets/homePagePicGreen.jpg'
import homePagePicOrange from '../../assets/homePagePicOrange.jpg'
import homePagePicPurple from '../../assets/homePagePicPurple.jpg'



const Home = ()=>{
    const [eventsInfo, setEventsInfo] = useState([]);

//     useEffect(()=>{
//         // async function getEvents_() {
//             const results = getAllEvents().then(
//             (response)=>{
//                 if (!response.status) {
                    
//                 }
//                 else if (response.status===200) {
                    
//                     setEventsInfo(response.data)}
//                 }
//         )
      
// },[]);

    return(

    <div className='mt-16 '>
        <div className='px-2  flex w-full justify-center'>
            <div className='h-64 w-full  sm:h-80 xl:h-96 xl:w-[70%] 2xl:h-96 mb-9'>
               <Carousel >
               <img className='rounded-md' src={homePagePicPurple} alt="..." />
               <img className='rounded-md' src={homePagePicOrange} alt="..." />
               <img className='rounded-md' src={homePagePicGreen} alt="..." />
               </Carousel>
            </div>
        </div>

        <CRow>
          <Link to="/events" className='mb-[8%]'>
            <h3 style={{color:'white'}}>Etkinlikler</h3>
          </Link>  
        </CRow>
    
        
    <Footer/>
    </div>
    );

    
}

export default Home;