

import '../App.css'
import myPdf from '../bg_basvuru_formu.pdf';

const Kvkk = ()=> {

    return(
        <div className='homebody'>
        <h4 style={{color:"white"}}>KVKK AYDINLATMA METNİ</h4>
<div className=' row justify-content-center'>
        <p style={{color:"white",textAlign:'left', width:'80%'}}>
        <p><strong>KİŞİSEL VERİLERİN KORUNMASI HAKKINDA AYDINLATMA METNİ</strong><br/>
6698 sayılı Kişisel Verilerin Korunması Kanunu’nun ("KVKK”) 10. maddesinden doğan aydınlatma yükümlülüğümüzü yerine
getirmek amacıyla, BuradaGo üzerinden alışveriş yapabilmeniz amacıyla işlenen kişisel verileriniz hakkında sizleri
bilgilendirmek isteriz.</p>

<p><strong>Veri Sorumlusu:</strong> Global Marks Bilişim Paz.İç ve Dış Tic.Ltd.Şti.<br/>
<strong>Veri Sorumlusunun Adresi:</strong> İzzet Paşa Mah. Yeni Yol Cad. NUROL TOWER No:3, İç Kapı No336, Şişli/İstanbul<br/>
Mersis No: 0396156158400001</p>

<p>BuradaGo tarafından, Üye veya Mağaza Sahibinin hangi kişisel verileri işlenmektedir ?
- Kimlik Bilgileriniz: TCKN/ VKN, adınız, soyadınız, unvan bilgisi,
-İletişim Bilgileriniz: cep telefonu, telefon numaranız, adres, e-posta adresi, KEP adresi
- Müşteri İşlem Bilgileriniz: sipariş, iade ve talep bilgileri, fatura bilgileri, teslimat işlem bilgileri,
- İşlem Güvenliği Bilgileriniz: IP adresi bilgileri, cihaz bilgileri, internet sitesi giriş çıkış bilgileri, kullanıcı adı ve şifre bilgileri,
-Hukuki İşlem Bilgileriniz: yetkili kişi, kurum ve kuruluşlarla yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler,
disiplin bilgileri,
- Finans Bilgileriniz: IBAN, banka ve hesap bilgileri,
- İşitsel Kayıt Bilgileriniz: çağrı merkeziyle görüşmeniz halinde ses kaydı veriniz.</p>
<strong>Kişisel verilerinizin işlenmesinin amaçları, toplama yöntemleri ve hukuki sebepleri nelerdir ?</strong><br/>
<strong>Kimlik, iletişim ve müşteri işlem bilgileriniz;</strong> sözleşmesel ilişkimiz kapsamında bizzat sizlerden ve/veya kamu kurumlarından
mobil uygulama, internet sitesi ya da posta yoluyla elektronik veya fiziki ortamda otomatik olarak aşağıdaki amaçlarla
toplanmakta ve işlenmektedir.<br/>
- Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
- Sözleşmenin yetkili bir kişi tarafından imzalandığının ve vergi mükellefiyetinin teyit edilebilmesi,
- <strong>Finans ve muhasebe süreçlerinin yürütülmesi,</strong>
- BuradaGo web sitesi vasıtasıyla satışı gerçekleştirmenizin sağlanması ve satın alım süreçlerinin yürütülmesi,
- BuradaGo politikalarına ve kurallarına uygun davranılmasının sağlanması ve denetlenmesi
- <strong>Bilgi güvenliği süreçlerinin yürütülmesi,</strong>
- Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
- BuradaGo web sitesine ilişkin olarak operasyonların güvenliğinin sağlanması,
- Sizin ve üyelerimizin deneyimini ve memnuniyetin arttırmaya yönelik faaliyetlerin yürütülmesi,
- <strong>İletişim faaliyetlerinin yürütülmesi,</strong>
- Süreçlerinize yönelik yardım ve destek taleplerinizin yönetilmesi,
- BuradaGo nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi,
- <strong>Stratejik analiz çalışmalarının yürütülmesi,</strong>
- Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
- Hukuk işlerinin takibi ve yürütülmesi,
- Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
- <strong>BuradaGo web sitesi satış performanslarının arttırılması,</strong>
- İnternet sitesi/ mobil uygulamalar üzerinden işlem yapanın kimlik bilgilerinin teyit edilmesi,
- Hukuk işlerinin takibi ve yürütülmesi, Hukuki İşlem Bilgileriniz, sözleşmesel ilişkimiz kapsamında bizzat sizlerden ve/veya
kamu kurumlarından internet sitesi ya da posta yoluyla elektronik veya fiziki ortamda otomatik olarak aşağıdaki amaçlarla
toplanmakta ve işlenmektedir.
- Sözleşmenin yetkili bir kişi tarafından imzalandığının ve vergi mükellefiyetinin teyit edilebilmesi,
- Teslimat süreçlerinin takibinin yapılması,
- Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
- Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
- Sözleşmenin yetkili bir kişi tarafından imzalandığının ve vergi mükellefiyetinin teyit edilebilmesi,
- <strong>Finans ve muhasebe süreçlerinin yürütülmesi,</strong>
BuradaGo kişisel verilerinizi yukarıda yer alan amaçlarla KVKK’nın 5, 8. ve 9. maddelerinde belirtilen ve aşağıda yer verilen
hukuka uygunluk sebeplerine dayanılarak işlenmektedir.
-6563 Elektronik Ticaretin Düzenlenmesi Hakkında Kanun başta olmak üzere Şirketimizin tabi olduğu mevzuatta açıkça
öngörülmüş olması,
- Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla kişisel verilerinizin işlenmesinin gerekli
olması,
- Hukuki yükümlülüğümüzün yerine getirebilmesi için zorunlu olması,
- Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,
- Temel hak ve özgürlüklerine zarar vermemek kaydıyla, şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu
olması.
- Yurtdışına kişisel veri aktarımı yapılması halinde açık rızanızın bulunması.
BuradaGo tarafından,misafir üyelerin hangi kişisel bilgileri işlenmektedir?
BuradaGo web sitesine üye olmaksızın sitede yer alan ürün veya etkinlikleri takip eden ve/veya satın alan kişiler “Misafir
Üye” olarak tanımlanmaktadır. BuradaGo web sitesi misafir üye olarak kullanmanız ve alışveriş yapmanız sırasında aşağıda
yer alan kişisel verileriniz işlenmektedir.
- Kimlik Bilgileriniz: TCKN/ VKN, adınız, soyadınız, unvan bilgisi,
- İletişim Bilgileriniz: cep telefonu, telefon numaranız, adres, e-posta adresi, KEP adresi
- Müşteri İşlem Bilgileriniz: sipariş, iade ve talep bilgileri, fatura bilgileri, teslimat işlem bilgileri,
- İşlem Güvenliği Bilgileriniz: IP adresi bilgileri, cihaz bilgileri, internet sitesi giriş çıkış bilgileri, kullanıcı adı ve şifre bilgileri,
- Hukuki İşlem Bilgileriniz: yetkili kişi, kurum ve kuruluşlarla yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler,
disiplin bilgileri,
- Finans Bilgileriniz: IBAN, banka ve hesap bilgileri,
- İşitsel Kayıt Bilgileriniz: çağrı merkeziyle görüşmeniz halinde ses kaydı veriniz.
BuradaGo kişisel verilerinizi paylaşıyor mu ?
Şirketimiz, kişisel verilerinizi “bilme gereği” ve “kullanma gereği” ilkelerine uygun olarak, gerekli veri minimizasyonunu
sağlayarak ve gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen göstermektedir. İş faaliyetlerinin
yürütülmesi veya denetimi, iş sürekliliğinin sağlanması, dijital altyapıların işletilmesi farklı paydaşlarla sürekli veri akışını
zaruri kıldığı için işlediğimiz kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak durumundayız. Ayrıca, sözleşmesel ve
kanuni yükümlülüklerimizi tam ve gereği gibi yerine getirebilmemiz için kişisel verilerinizin doğru ve güncel olması çok
önemlidir. Bunun için de muhtelif iş ortaklarıyla ve hizmet sağlayıcılarla çalışmak durumundayız. Bu kapsamda, yasal
yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere talep edilmesi halinde yetkili kişi, kurum ve kuruluşlarla kişisel
verileriniz paylaşılmaktadır. Kişisel verileriniz, pazaryeri operasyonun yürütülmesi, iş sürekliliğinin sağlanması, bilgi
güvenliğinin sağlanması, satışını yaptığınız ürünlere yönelik olarak satın alım süreçlerinin yürütülmesi amaçları başta
olmak üzere ve işbu aydınlatma metninin birinci maddesinde belirtilen amaçlarla sınırlı olarak gerekli olduğu ölçüde yurtiçi
ve/veya yurtdışında bulunan iştiraklerimize, tedarikçilerimize, iş ortaklarımıza, anlaşmalı olduğumuz bankalara ve ürün
veya hizmet aldığımız üçüncü kişilerle paylaşılabilmektedir.
BuradaGo kişisel verilerinizi nasıl koruyor?
BuradaGo ile paylaşılan kişisel veriler, BuradaGo gözetimi ve kontrolü altındadır. BuradaGo, yürürlükteki ilgili mevzuat
hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik
önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki
yükümlülüğümüzün bilincinde olarak;
-Veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri
yaptırılmaktadır.
-Web sitesi, mobil site ve mobil uygulama aracılığıyla BuradaGo’ya ilettiğiniz kişisel verileriniz SSL (Secure Sockets Layer)
teknolojisi kullanılarak korunmaktadır.
-Kişisel veri işleme faaliyetlerine ilişkin düzenli olarak risk analizleri yapılmakta ve risklerin azaltılması için aksiyonlar
alınmaktadır.
- Kişisel verilere yetkisiz erişimleri engellemek için erişim ve yetki kontrolleri uygulanmaktadır.
-Bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.
<strong>KVKK’dan doğan haklarınız nelerdir?</strong><br/>
KVKK uyarınca kişisel verilerinizin;<br/>
a) işlenip işlenmediğini öğrenme,
b) işlenmişse bilgi talep etme,
c) işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,
ç) yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,
d) eksik / yanlış işlenmişse düzeltilmesini isteme,
e) KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini/yok edilmesini isteme,
f) aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme, münhasıran
otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme, KVKK’ya aykırı olarak
işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarına sahip olduğunuzu hatırlatmak
isteriz.<br/>
<strong>Hak ve Talepleriniz İçin Bizimle İletişime Geçmek İsterseniz</strong> KVKK’nın “ilgili kişinin haklarını düzenleyen” 11. maddesi
kapsamındaki taleplerinizi “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ” ile bize bildirebilirsiniz. İlgili kişi
olarak, haklarınıza ilişkin taleplerinizi, BuradaGo'a iletmeniz durumunda BuradaGo talebinizi en geç 30 (otuz) gün içinde
ücretsiz olarak sonuçlandıracaktır. Ancak, başvurunuza verilecek yanıtın mevzuat uyarınca bir maliyet gerektirmesi halinde
Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret BuradaGo tarafından talep edilebilecektir.<br/>
<strong>Hak ve talepleriniz için iletişim</strong><br/>
Kişisel verilerinizle ilgili sorularınızı ve taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları hakkında Tebliğ’de belirtilen
şartlara uygun düzenlenmiş dilekçeyle ya da " Global Marks Bilişim Paz.İç ve Dış Tic.Ltd.Şti." ile aşağıdaki yöntemlerle
iletebilirsiniz. Başvuru formuna <a href={myPdf}>buradan ulaşabilirsiniz.</a>
{/* </p> */}
{/* <table border="1" style={{color:'white',marginBottom:'20px', marginTop:'20px'}} >
  <tr>
    <th>Başvuru Yöntemi</th>
    <th>Başvurunun Yapılacağı Adres</th>
  </tr>
  <tr>
    <td>Yazılı Başvuru</td>
    <td>Global Marks Bilişim Paz.İç ve Dış Tic.Ltd.Şti.</td>
  </tr>
  <tr>
    <td>Güvenli Elektronik İmza veya Mobil İmza İle İmzalanarak Kayıtlı Elektronik Posta (KEP)</td>
    <td>globalmarksbilisim@hs01.kep.tr</td>
  </tr>
  <tr>
    <td>Şirketimize daha önce bildirilen ve sistemde kayıtlı bulunan elektronik posta adresinizden</td>
    <td>kvkk@buradago.com</td>
  </tr>
</table> */}

                <p><strong><u>Şahsen Yazılı Başvuru</u></strong> yoluna başvuracak İlgili Kişilerin kimliğini tevsik edici belgeleri ibraz etmesi zorunludur. Bu
                bağlamda <strong>İzzet Paşa Mah. Yeni Yol Cad. NUROL TOWER No:3, İç Kapı No336, Şişli/İstanbul</strong> adresine yapacağınız şahsen
                yazılı başvurularda başvuru formuyla birlikte kimlik fotokopisinin yalnızca ön yüzünün (kan grubu ve din hanesi
                gözükmeyecek şekilde) ibraz edilmesini rica ederiz.</p>
                <p><strong>-Başvuru formunun tebligat zarfına veya e-postanın konu kısmına <u>"Kişisel Verilerin Korunması Kanunu İlgili Kişi Talebi"</u></strong>
                yazılmasını rica ederiz.</p>
                <p>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, <strong>BuradaSatıyor</strong>’a iletmeniz durumunda <strong>BuradaSatıyor</strong> talebinizi
                en geç <strong>30 (otuz) gün içinde</strong> ücretsiz olarak sonuçlandıracaktır. Ancak, başvurunuza verilecek yanıtın mevzuat uyarınca bir
                maliyet gerektirmesi halinde <strong>BuradaSatıyor</strong> tarafından <strong>Kişisel Verileri Koruma Kurulu</strong> tarafından belirlenen tarifedeki ücret
                talep edilebilecektir.</p>
                <p><strong><u>AYDINLATMA METNİ HAKKINDA</u></strong></p>
                <p><strong><em>BuradaGo</em></strong>, <strong>Kişisel Verilerin Korunması Hakkında Açıklama</strong> metnini yürürlükteki mevzuatta yapılabilecek değişiklikler
                çerçevesinde her zaman güncelleme hakkını saklı tutar.</p>
         </p>   
        </div>

        </div>

    );


}

export default Kvkk;