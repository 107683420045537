import { useEffect, useState } from "react";
import "../../App.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CitiesJson from "../cities";
import { FETCH_BILLING_ADDRESS_URL, generalGetMethos, getCurrentUser, postPrepaymentData } from "../requests";
import { formatToTL } from "../../helpers";
import EditBillnAddress from "../billing/edit-billn-address";
import AddBillingAddress from "../billing/add-bill-address";
import DisplayBillingAddress from "../billing/display-billn-address";
// import { FETCH_BILLING_ADDRESS_URL, generalGetMethos, getCurrentUser, postPrepaymentData } from "./requests";
// import CountrySelector from "./country-selector";
// import CitiesJson from "./cities";
// import DisplayBillingAddress from "./billing/display-billn-address";
// import AddBillingAddress from "./billing/add-bill-address";
// import EditBillnAddress from "./billing/edit-billn-address";
// import { formatToTL } from "../helpers";


const cities = Object.keys(CitiesJson);

  const Addresslerim = (props)=>{

  //address types
  // const ind_add_type = 'individual';
  // const org_add_type = 'organizational';


  //manage what component to display
  const [add_address, setadd_address] = useState(false);
  const [edit_address, setedit_address] = useState(false);
  const [showPaymentUI, setshowPaymentUI] = useState(false);

  const [selected_address, setselected_address] = useState({});
  const [all_addresses, setall_adresses] = useState([]);
  const [toggleFectchAddress, settoggleFectchAddress] = useState(false);


  //setup payment UI after address added or edited or selected
  const readyForPayment = () => { 
    setadd_address(false);
    setedit_address(false);
    setshowPaymentUI(true);
    settoggleFectchAddress(!toggleFectchAddress)
  }

  //handle address selected
  const handleAddressSelected = (address_id) => { 
      let selectedAddrs = all_addresses.filter(item => ( item.id==address_id));
      setselected_address(selectedAddrs[0]);
  }


  const handlePay = (event) =>{ 
    event.preventDefault();
    // console.log(JSON.stringify({full_name,last_name,user_email,event_id,user_id,
    //   fatura_address,phone, etkinlikUcreti}))
  }

  //function for triggring editing of address
  const onEditAddress = (address_) => { 
    setshowPaymentUI(false);
    setedit_address(true);
    setadd_address(false);
    setselected_address(address_);
  }

  //function for starting new address
  function startNewAddress() {
    setshowPaymentUI(false);
    setedit_address(false);
    setadd_address(true);
  }

  //get user billing addresses
  const getBillnAddresses = () => { 
    generalGetMethos(FETCH_BILLING_ADDRESS_URL).then(
      (response) => { 
        if (!response.status) {
          //set error message, something went wrong
        } else if(response.status===200) {
          if (response.data.addresses.length>0) {
            setall_adresses(response.data.addresses);
            // setselected_address(response.data.addresses[0])
            // readyForPayment();
            setadd_address(false);
            setedit_address(false);
            setshowPaymentUI(true);
          }else{
            setedit_address(false);
            setshowPaymentUI(false);
            setadd_address(true);
          }
          //set page to make payment
          // console.log('addresses', response.data);
        }
      }
    ).catch()
  }

  useEffect(() => { 
    getBillnAddresses();
    },[toggleFectchAddress])

  useEffect(()=>{ 
    getBillnAddresses()
  

    },[]);




    return(
      <div className="mt-14 w-full h-full" style={{color:'white'}}>

            <div className=" h-full pb-[1px] sm:w-[60%] md:w-[30%] w-[95%]
             mt-3 mx-auto">
                
                  {/* address addition button */}
                  {showPaymentUI&&
                  <div className="w-full flex mb-2 px-2  text-xs h-[2rem] ">
                    {/* add address button */}
                    <button
                     className="bg-blue-400 h-full mx-3 mt-1 px-1 rounded-md
                     "
                     onClick={() => { startNewAddress() }}
                     >
                      Adres Ekle 
                    </button> 
                  </div>
                  }

                {/* address display/edit/add area */}
                <div className="overflow-y-auto mt-3  flex-col mx-auto h-[35rem] sm:h-[34rem]">                
                 { edit_address&&<EditBillnAddress
                  props={{'address':selected_address,'readyForPayment':readyForPayment,}}/>}
                  { add_address&&<AddBillingAddress props={{'readyForPayment':readyForPayment}}/>}
                  { showPaymentUI&&
                    all_addresses.map((item, index) => (
                      <div>
                        <DisplayBillingAddress props={{'address':item,
                          'onEditAddress':onEditAddress}}/>
                      </div>
                        
                    ))                  
                    }                
                </div>              
            </div>
      </div>
    )
}

export default Addresslerim;