import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Table from 'react-bootstrap/Table';
// import '../App.css';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MY_EVENTS_URL, generalGetMethos } from '../requests';

const FenomenEtkinlikTable = ({ data }) => {
  const [allevents, setallevents] = useState([]);

  useEffect(() => {

    generalGetMethos(MY_EVENTS_URL).then(
      (response) => { 
        if (!response.status) {
          
          //handle error
        } else if(response.status===200){
          if (response.data.events.length>0) {
            setallevents(response.data.events)
          }
          // console.log('my events',response.data);
        }
       }
       
    ).catch()
   
  }, [])
  


  return (
    <Table striped bordered hover className='table'>
      <thead>
        <tr>
          <th>Etkinlik</th>
          <th>Tarihi</th>
          <th>Eylem</th>
        </tr>
      </thead>
      <tbody>
        {allevents&&allevents.map((item, index) => (
          <tr key={index} className='border-t '>
           
          <td className='h-fit text-xs'>{item.etkinlik_adi}</td>
          <td className='h-fit text-xs'>{format(Date(item.etkinlik_tarihi),"dd-MM-yyyy") }</td>
          <td className='flex space-x-3 justify-center'>
            <Link
             to={"/fenomen-etkinlik"} state={{'event_id':item.id}}
             className='text-xs text-gray-800 no-underline cursor-pointer bg-blue-400 rounded-md w-fit h-fit px-2'>
              Detaylar
            </Link>
            <Link
            to={"/event-attendees"} state={{'event_id':item.id}}
             className='text-xs text-gray-800 no-underline cursor-pointer bg-blue-400 rounded-md w-fit h-fit px-2'>
              Katılımcılar
            </Link>
          </td>
          </tr>


        ))}
        <tr>
                      
          </tr>

      </tbody>
    </Table>
  );
};

export default FenomenEtkinlikTable;
