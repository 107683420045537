
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

const FenomenMenu = (expand)=>{

    return(
        <>
        <NavDropdown
                    title="Etkinlikler"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="event-request">Yeni Etkinlik Talebi</NavDropdown.Item>
                   
                    {/* <NavDropdown.Divider /> */}
                    {/* <NavDropdown.Item onClick={}>
                      Çıkış
                    </NavDropdown.Item> */}
        </NavDropdown>

        <NavDropdown
                    title="Muhasebe"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="kesilecek-fatura">Kesilecek Faturalar</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Onaylanan Faturalar</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Ödemeler</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Belgelerim</NavDropdown.Item>
                   
                    {/* <NavDropdown.Divider /> */}
                    {/* <NavDropdown.Item onClick={}>
                      Çıkış
                    </NavDropdown.Item> */}
        </NavDropdown>

        <NavDropdown
                    title="Mesajlar"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="message-admin">Site Adminine Yaz/Oku</NavDropdown.Item>
                    {/* <NavDropdown.Item Link as={Link} to="">Kullanıcıya Yaz</NavDropdown.Item> */}
                   
                    {/* <NavDropdown.Divider /> */}
                    {/* <NavDropdown.Item onClick={}>
                      Çıkış
                    </NavDropdown.Item> */}
        </NavDropdown>
        </>);
}

export default FenomenMenu;