import '../App.css'

const SiteKullanim = ()=> {

    return(
        <div className='homebody' style={{}}>
        <h4 style={{color:"white"}}>SİTE KULLANIM KOŞULLARI</h4>
<div className=' row justify-content-center'>
        <p style={{color:"white",textAlign:'left', width:'80%'}}>
        Bu internet sitesine girmeniz veya bu internet sitesindeki herhangi bir bilgiyi kullanmanız aşağıdaki koşulları kabul ettiğiniz anlamına gelir. <br/>
Bu internet sitesine girilmesi, sitenin ya da sitedeki bilgilerin ve diğer verilerin programların vs. kullanılması sebebiyle, sözleşmenin ihlali, haksız fiil, ya da başkaca sebeplere binaen, doğabilecek doğrudan ya da dolaylı hiçbir zarardan Global Marks Bilişim Paz.İç ve Dış Tic.Ltd.Şti. sorumlu değildir. <br/>
GLOBAL MARKS, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti hususunda herhangi bir sorumluluk kabul etmez. <br/>
GLOBAL MARKS işbu site ve site uzantısında mevcut her tür hizmet, ürün, siteyi kullanma koşulları ile sitede sunulan bilgileri önceden bir ihtara gerek olmaksızın değiştirme, siteyi yeniden organize etme, yayını durdurma hakkını saklı tutar. Değişiklikler sitede yayım anında yürürlüğe girer. Sitenin kullanımı ya da siteye giriş ile bu değişiklikler de kabul edilmiş sayılır. <br/>
Bu koşullar link verilen diğer web sayfaları için de geçerlidir. GLOBAL MARKS, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti, silinme, kayıp, işlemin veya iletişimin gecikmesi, bilgisayar virüsü, iletişim hatası, hırsızlık, imha veya izinsiz olarak kayıtlara girilmesi, değiştirilmesi veya kullanılması hususunda herhangi bir sorumluluk kabul etmez. <br/>
Bu internet sitesi GLOBAL MARKS 'ın kontrolü altında olmayan başka internet sitelerine bağlantı veya referans içerebilir. GLOBAL MARKS , bu sitelerin içerikleri veya içerdikleri diğer bağlantılardan sorumlu değildir. <br/>
GLOBAL MARKS bu internet sitesinin genel görünüm ve dizaynı ile internet sitesindeki tüm bilgi, resim, BuradaGo markası ve diğer markalar, www.buradago.com alan adı, logo, ikon, demonstratif, yazılı, elektronik, grafik veya makinede okunabilir şekilde sunulan teknik veriler, bilgisayar yazılımları, uygulanan satış sistemi, iş metodu ve iş modeli de dahil tüm materyallerin ve bunlara ilişkin fikri ve sınai mülkiyet haklarının sahibi veya lisans sahibidir ve yasal koruma altındadır. <br/>
Internet sitesinde bulunan hiçbir Materyal; önceden izin alınmadan ve kaynak gösterilmeden, kod ve yazılım da dahil olmak üzere, değiştirilemez, kopyalanamaz, çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz, başka bir bilgisayara yüklenemez, postalanamaz, iletilemez, sunulamaz ya da dağıtılamaz. Internet sitesinin bütünü veya bir kısmı başka bir internet sitesinde izinsiz olarak kullanılamaz. Aksine hareketler hukuki ve cezai sorumluluğu gerektirir GLOBAL MARKS 'ın burada açıkça belirtilmeyen diğer tüm hakları saklıdır. <br/>
GLOBAL MARKS,site içerisindeki ''Yorumları'' ve ''Sorulan Sorular'' dan sorumlu değildir;Kişisel veya Kurumsal hakaret içeren ve yanlış anlaşılmaya sebep olacak,kişi özgürlük haklarına müdahale sayılacak,küfür ve cinsellik içeren yazıları editleme yada silme hakkına sahiptir. <br/>
GLOBAL MARKS, dilediği zaman bu yasal uyarı sayfasının içeriğini güncelleme yetkisini saklı tutmaktadır ve kullanıcılarına siteye her girişte yasal uyarı sayfasını ziyaret etmelerini tavsiye etmektedir.

        </p>
        </div>

        </div>

    );


}

export default SiteKullanim;