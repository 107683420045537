import '@coreui/coreui/dist/css/coreui.min.css';
import { CCard, CCardImage,CCardBody,CCardTitle,CCardText,CButton,CCardHeader,CCollapse,CContainer  } from '@coreui/react';
import { useState } from 'react';

// import logo from '../logo.svg';
// import tarkan from '../tarkan.jpg';
import '../App.css';
import { useNavigate } from 'react-router-dom';
// import { CDBCollapse } from 'cdbreact';








const Etkinlik = ({reactImage})=> {
    const [visible, setVisible] = useState(false);

    const [etkinlikAdi, setEtkinlikAdi] = useState('Olcay Şahan ile Futbol Sohbeti');
    const [etkinlikUcreti, setEtkinlikUcreti] = useState('700,00 TL');

    const navigateThere = useNavigate();

    const handleSatinAlClick = ()=>{

        let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

        if (loginVal=='true') {            
               
        localStorage.setItem('etkinlikAdi', JSON.stringify(etkinlikAdi));
        localStorage.setItem('etkinlikUcreti', JSON.stringify(etkinlikUcreti));

        //Later move these into stateful variables
        localStorage.setItem('etkinlikTarihi', JSON.stringify('25 Aralık 2023'));
        localStorage.setItem('etkinlikMekan', JSON.stringify('Bahçelievler-Hot Chocolate Cafe'));
        localStorage.setItem('etkinlikKodu', JSON.stringify('1033')); //Note: this is the same as ethkinlik ID
        navigateThere('/satin-al');

        }else{
            navigateThere('/giris');
        }
        
    }

    return(
        <div>
                            
            <CCard style={{ width: '20rem',marginBottom:'30px' }}>
            <CCardHeader>Etkinlik tarihi: 25 Aralık 2023</CCardHeader>
            <CCardImage orientation="bottom" src={reactImage} style={{maxHeight:'335px'}}/>
             {/* style={{maxHeight:'367px'}} /> */}
            <CCardBody>
            <CCardTitle style={{fontSize:'17px',height:'40px'}}>Olcay Şahan ile Futbol Sohbeti</CCardTitle>
            <CCardText onClick={() => setVisible(!visible)}>
             {!visible? (<p className='footer-text'>Detayları Gör.</p>):(<p className='footer-text'>Detayları Kapat.</p>)}
            </CCardText>
            
                {visible?(<p visible={visible}>
                    
                    <strong>Katılım Ücreti: 750,00 TL</strong> <br></br>
                    Katılacak Kişi Sayısı: <strong> 20 kişi</strong> <br></br>
                    Etkinlik Kodu : 1033 <br></br>                    
                    Saat: 19:00 - 20:00 <br></br>
                    Mekan: Bahçelievler-Hot Chocolate Cafe <br></br>
                    
                   
                </p>):(<></>)}

            
            <CButton onClick={handleSatinAlClick} >Satın al</CButton>
            </CCardBody>
        </CCard>

        
          

        
        
        </div>
    );
}

export default Etkinlik;