import React, { useState } from 'react'

const EventRequest = () => {

    const [formData, setFormData] = useState({
      socialMedia: '',
      username: '',
      followerCount: '',
      content: '',
      fullName: '',
      mobile: '',
      contactNo: '',
      desiredCity: '',
      desiredDate: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission here
    //   console.log(formData);
    };


  return (
    <div className='flex h-screen w-full justify-center items-center'>
         <div className='text-white'>
            <h2 className='mb-6 font-semibold'>Etkinlik İsteme Formu</h2>
            <form onSubmit={handleSubmit} className='space-y-2'>
            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="socialMedia">Kullandığınız Sosyal Medya*:</label>
                <input className="rounded-md text-gray-600" type="text" id="socialMedia" name="socialMedia" value={formData.socialMedia} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="username">Sosyal Medya Kullanıcı Adınız*:</label>
                <input className="rounded-md text-gray-600" type="text" id="username" name="username" value={formData.username} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="followerCount">Takipçi Sayınız*:</label>
                <input className="rounded-md text-gray-600" type="number" id="followerCount" name="followerCount" value={formData.followerCount} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="content">Paylaşımlarınızın İçeriği*:</label>
                <input className="rounded-md text-gray-600" type="text" id="content" name="content" value={formData.content} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="fullName">Adınız Soyadınız*:</label>
                <input className="rounded-md text-gray-600" type="text" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="mobile">İletişim Mobil*:</label>
                <input className="rounded-md text-gray-600" type="text" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="contactNo">İletişim No*:</label>
                <input className="rounded-md text-gray-600" type="text" id="contactNo" name="contactNo" value={formData.contactNo} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="desiredCity">Etkinlik Yapmak İstediğiniz İl:</label>
                <input className="rounded-md text-gray-600" type="text" id="desiredCity" name="desiredCity" value={formData.desiredCity} onChange={handleChange} />
            </div>

            <div className='flex justify-around'>
                <label className='w-[10rem] text-start text-sm px-1' htmlFor="desiredDate">Etkinlik Yapmak İstediğiniz Tarih:</label>
                <input className="rounded-md text-gray-600" type="text" id="desiredDate" name="desiredDate" value={formData.desiredDate} onChange={handleChange} />
            </div>

            <button type="submit"
            className='bg-blue-500 px-3 rounded-md mt-3'
            >
                Gönder</button>
            </form>
        </div>

    </div>
  )
}

export default EventRequest