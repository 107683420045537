import React, { useState,useRef, useContext  } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { withRouter, useNavigate } from "react-router-dom";
import '@coreui/coreui/dist/css/coreui.min.css';

import "../App.css";
import MyFooter from '../components/footer';
// import { CContainer } from '@coreui/react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { AUTH_COOKIE_LINK, LOGIN_URL_PROD, PASSWORD_RESET_MAIL_URL, generalPostMethod } from '../components/requests';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;



const PasswordResetEmail = () => { 
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const navigateThere = useNavigate();

  // const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

  //success component
  const successComp =
  <div className='w-full flex justify-center'>
    <h1 className='text-white bg-green-400 py-[3rem] mx-[4rem] mt-[8rem] lg:w-[60%]'>
      Girdiğiniz mail adresine şifre deiğiştirme linkini göderilmiş.

    </h1>
  </div>

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');

    generalPostMethod(PASSWORD_RESET_MAIL_URL,{email:email}).then((response) => { 
      if (response.status===200) {
        setSuccess(true);
      } else {
        if (response.data) {
          setErrorMessage(response.data.message)
        } else {
          setErrorMessage("Girdiğiniz mail yanlış yada bulunamadı")
        }
      }
     }) };

    // navigateThere('/password-reset')
    // Replace this part with your actual login logic
    // if (username === 'exampleuser' && password === 'examplepassword') {
    //   // Successful login logic (e.g., redirect to dashboard)
    //   console.log('Login successful!');
    // } else {
    //   setErrorMessage('Yanlış bilgileri girdiniz.');

    // if button enabled with JS hack
    // const v1 = USER_REGEX.test(name);
    // const v2 = PWD_REGEX.test(pwd);
    // if (!v1 || !v2) {
    //   console.log('There was an error');
    //     setErrMsg("Invalid Entry");
    //     return;
       
  

  return (
    <>
    {success? successComp:
    <div  className="login-page">
      {errorMessage&&
      <p className='bg-red-400'>{"Hata: "+errorMessage}</p> 
      }
        <h2 style={{color:'white'}}>Üye Maili</h2> <br/>
    <Form onSubmit={handleSubmit}>
      <p className='text-red-300 text-xs'>
        Aşağida gireceğiniz mail addressine doğru ise şifre yenileme linkini göderilecektir.
      </p>
      <Form.Group className="mb-3" >
        {/* <Form.Label>Kullanici Adı veya mail</Form.Label> */}
        <Form.Control type="email" id="username" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Mail adresi (örnek@gmail.com)" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>
      <Button className=''  type="submit">
        Gönder
   
      </Button>
    </Form>
    </div>
    }
    </>
    
    
    
    
   
  );
};

export default PasswordResetEmail;
