// import React from "react"
import { Nav, NavItem } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "./header";
import { useContext } from "react";
import HeaderLoggedIn from "./header-loggedIn";



const Layout = () =>{
// const {isLogin} = useContext(isLoginContext); 


 return(   
<div className="App">
<header className="App-header">
{/* {isLogin?<HeaderLoggedIn/>:<Header />} */}
<Header />
  
</header>
<Outlet />
</div>
 );
 
}

export default Layout;