import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SingleEvent from './single-event';
import EventOwnerEvents from './pages/EventOwnerEvents';


// const [urlParam, seturlParam] = useState('');

const DynamicComponent = () => {

    function isNumber(str) {
        return !isNaN(str.trim()) && str.trim() !== '';
    }

    const useParam = useParams();
    const urlParam = useParam.urlParam;

    //return specific component based on whether
    //urlComponent is string or int
if (isNumber(urlParam)) {
    return <SingleEvent event_id={urlParam}/>
} else {
    return <EventOwnerEvents ownername={urlParam}/>
    
}
}

export default DynamicComponent