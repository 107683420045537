// import logo from '../../logo.svg';
import '../../App.css';

import '@coreui/coreui/dist/css/coreui.min.css';
import { CContainer,CRow,CCol  } from '@coreui/react';

import { Modal} from "flowbite-react";
import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';

import Etkinlik from '../etkinlik-card';
import Etkinlik2 from '../etkinlik-card2';
import logo from '../../logo.svg';
import tarkan from '../../tarkan.jpg';
import rashid from '../../rashid.jpg';
import fatih2 from '../../fatih2.jpeg';
import olcay from '../../olcay.jpeg';
import Footer from '../footer';
import { GET_EVENTS_BY_OWVER_NAME, generalGetMethos, getAllEvents } from '../requests';
import EtkinlikGeneral from '../etkinlikGeneral-card';


const EventOwnerEvents = ({ownername})=>{
    const [eventsInfo, setEventsInfo] = useState([]);
    const [server_timestamp, setserver_timestamp] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [evenRules, setevenRules] = useState("");
    const [eventOwnerName, seteventOwnerName] = useState("")

    useEffect(()=>{
        // async function getEvents_() {
            const results = generalGetMethos(GET_EVENTS_BY_OWVER_NAME+ownername).then(
            (response)=>{
                if (!response.status) {
                    
                }
                else if (response.status===200) {
                        if (response.data.events.length>0) {
                            setEventsInfo(response.data.events)
                            seteventOwnerName(
                                response.data.events[0].first_name+" "+response.data.events[0].last_name
                            )
                            setserver_timestamp(response.data.timestamp)
                        }
                    }
                    
                }
        )
      
},[]);

    return(

        <div className='homebody'>
            {/* Modal for displaying event rules */}

            <Modal show={openModal}  onClose={() => setOpenModal(false)}>
                <Modal.Header>Etkinliğin Kurarlari</Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6 p-6 overflow-y-auto h-[15rem]">
                            <p
                             className="text-base leading-relaxed text-gray-500
                              dark:text-gray-400">
                                {evenRules&&evenRules}
                            </p>
                        </div>
                    </Modal.Body>
            </Modal>

            <CRow>
            <h3 style={{color:'white'}}>{eventOwnerName&&eventOwnerName +" Etkinlikler"} </h3>
            </CRow>
            <div className='row d-flex'>

                {eventsInfo&&eventsInfo.map((componentInfo)=>(
                    <div className='col justify-content-center  d-flex'>
                        <EtkinlikGeneral
                         props={componentInfo} server_timestamp={server_timestamp}
                                setOpenModal={setOpenModal} setevenRules={setevenRules}
                        />
                        </div>))
                }                              

            </div>    
        
            <Footer/>
        </div>
    );

    
}

export default EventOwnerEvents;