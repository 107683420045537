import { useLocation } from "react-router-dom";
import MyFooter from "../components/footer";
import Cerez from "../components/cerez-politikasi";
import { useEffect } from "react";




const FooterPage2 = ()=>{

    const location = useLocation();
        // const id = location.state.id
    

    // useEffect(()=>{console.log(location.state.id)},[]);
    return(
        <div>
            <Cerez/>
            <MyFooter/>
        </div>
    );



}

export default FooterPage2;