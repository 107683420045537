

import '../App.css'

const Iletisim = ()=> {

    return(
        <div className='homebody' style={{}}>
        <h4 style={{color:"white"}}>İletişim</h4>
<div className='row justify-content-center'>
        <p style={{color:"white",textAlign:'left', width:'80%'}}>

        <p>

          Global Marks Bilişim Paz.İç ve Dış Tic.Ltd.Şti.<br/>
          Adres : İzzet Paşa Mah. Yeni Yol Cad. NUROL TOWER No:3, İç Kapı No336, Şişli/İstanbul<br/>
          Tel.: 0 850 309 33 14<br/>
          Kağıthane V.D.: 396 156 1584<br/>
          Ticaret Sicil No.: 435766-5<br/>
          Mersis No.: 0396156158400001<br/>
          Kep Adresi : globalmarksbilisim@hs01.kep.tr<br/>
          İletişim-Destek Mail : destek@buradago.com<br/>
          Muhasebe-Cari Hesap Mail : muhasebe@buradago.com<br/>
          Whatsapp Destek Hattı : 0536 219 33 14<br/><br/>
        </p>

        </p>
        </div>

        </div>

    );


}

export default Iletisim;