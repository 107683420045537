import { useState } from "react";
import { formatToTL } from "../helpers";
import { disapproveAttendee } from "./requests";


const KatilimciListItem = (props)=>{

    var user_id = '';
    var user_type ='';
    var first_name ='';
    var last_name ='';

    if (JSON.parse(localStorage.getItem('user_id'))) {  
        user_id = JSON.parse(localStorage.getItem('user_id'))
        user_type = JSON.parse(localStorage.getItem('user_type'))
        first_name = JSON.parse(localStorage.getItem('first_name'))
        last_name = JSON.parse(localStorage.getItem('last_name') )
       }

  const [toggleLoadAttendees, settoggleLoadAttendees] = useState(true);
  const [errorMessage, seterrorMessage] = useState('');


    //disapprove an attendee
    // function disApprove() {
    //     //clear previus error message if any
    //     seterrorMessage('')
    //     disapproveAttendee({"user_id":props.item.user_id,"event_id":props.item.event_id}).then(
    //         (response) => {
    //             if (!response.status) {
    //                 seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")
    //             }else if (response.status===200) {
    //                 settoggleLoadAttendees(!toggleLoadAttendees);
    //             }
    //         }
    //     )
    // }

// console.log(props.index)
    return(
        <div className={"  border-bottom border-blue "}>
            <table className="w-full">
                <thead>
                        <th>Sıra</th>
                        <th>İsim</th>
                        <th>Tutar</th>
                </thead>

                <tbody>
                {props.leaderBoard.map((record, index) => (
                    <tr className={(index+1 >props.maxParticipants?" bg-red-400 ":" ")+" "}>
                        <td>{index+1}</td>
                        <td>
                            
                            {record.user_id != user_id ? record.first_name+' ...':
                            first_name +' '+last_name}
                        </td>
                        <td>
                            {formatToTL(record.total_amount,"database") +' TL'}
                        </td>
                    </tr>
                    ))}
                    
                </tbody>
            </table>
           

        </div>
    );
}

export default KatilimciListItem;