

import '../App.css'

const Cerez = ()=> {

    return(
        <div className='homebody' style={{}}>
        <h4 style={{color:"white"}}>ÇEREZLERE YÖNELİK AYDINLATMA METNİ</h4>
<div className=' row justify-content-center'>
        <p style={{color:"white",textAlign:'left', width:'80%'}}>
        <p><strong><u>Global Marks Bilişim Paz.İç ve Dış Tic.Ltd.Şti. ("BuradaGo" veya “Şirket”)</u></strong> olarak; kişisel verilerinizin gizliliği ve güvenliği
en önemli önceliklerimiz arasındadır. Bu kapsamda, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun ("KVKK”) 10.
maddesinden doğan aydınlatma yükümlülüğümüzü yerine getirmek amacıyla kişisel verileriniz hakkında sizleri
bilgilendirmek isteriz. Kişisel veri, kimliğinizi belirli ya da belirlenebilir kılan her türlü bilgi anlamına gelmektedir.</p>

<p><strong><u>GLOBAL MARKS'a ait olan www.buradago.com uzantılı internet sitesi, çerez (cookie) kullanan bir sitedir.</u></strong> Çerez;
kullanılmakta olan terminal cihazlara depolanmak suretiyle söz konusu cihazın tespit edilmesine olanak sağlayan,
çoğunlukla harf ve sayı kombinasyonlarından oluşan düşük boyutlu küçük metin dosyasıdır.</p>

<p><strong><u>İşbu Çerezlere Yönelik Aydınlatma Metni’nin amacı,</u></strong> www.buradago.com uzantılı internet sitemizde kullanılan
çerezlerin terminal cihazlarınıza yerleştirilmesi aracılığıyla otomatik yolla elde edilen kişisel verilerinizin işlenmesine
ilişkin olarak, hangi amaçlarla hangi tür çerezleri kullandığımız ve bu çerezleri nasıl yönetebileceğiniz hakkında sizlere
bilgi vermektir.</p>

<p><strong><u>1.BURADAGO TARAFINDAN HANGİ TÜR ÇEREZLER KULLANILMAKTADIR?</u></strong></p>

<p><strong><u>Çerez türlerini, kullanım amaçlarına göre çerezler, sürelerine göre çerezler ve taraflarına göre çerezler olmak üzere</u></strong>
üçlü bir tasnife tabi tutmak mümkündür.</p>

<p><strong><u>1.1. Kullanım Amaçlarına Göre Çerezler</u></strong></p>

<p><strong><u>BuradaGo olarak, kullanım amaçlarına göre dört tür çerez kullanmaktayız.</u></strong> Bunlar: Performans Tanımlama (Analitik)
Çerezleri, Hedefleme Amaçlı Tanımlama Çerezleri, İşlevsel Tanımlama Çerezleri ve Zorunlu Tanımlama (Kesinlikle
Gerekli) Çerezleridir. (BuradaGo hizmetlerinin sunulduğu www.buradago.com uzantılı internet sitesi ve mobil
uygulama birlikte “Platform” olarak anılacaktır.)</p>

<p><strong><u>1.1.1. Performans Tanımlama (Analitik) Çerezleri:</u></strong> Bu çerezler, web sitemizin performansını ölçebilmemiz ve
geliştirebilmemiz için ziyaretleri ve trafiği izlememize; hangi sayfaların en çok ve en az popüler olduğunu öğrenmemize
ve müşterilerin sitede nasıl gezindiğini görmemize yardımcı olmaktadır. Bu çerezlerin topladığı tüm bilgiler anonim hale
getirilmektedir. Bu çerezlere izin vermemeniz halinde tarafınızca sitemizin ne zaman ziyaret edildiği bilinemez ve
performansınız izlenemez. Örneğin: Tıklama sıklığınızın takibi.

<strong><u>1.1.2. Hedefleme Amaçlı Tanımlama Çerezleri:</u></strong> Bu çerezler tercih ve ilgi alanlarınıza yönelik ürün sunmak amacıyla
kullanılmakta olup, reklam ortaklarımız tarafından web sitemizden ayarlanabilmektedir. Bu sayede hedefleme amaçlı
tanımlama çerezleri, reklam ortaklarımız tarafından ilgi alanlarınızın profilini oluşturmak ve diğer sitelerdeki ilgili
reklamları göstermek için kullanılabilir. Hedefleme amaçlı tanımlama çerezleri doğrudan kişisel verilerinizi
saklamamakta; ancak tarayıcınızın ve internet cihazınızın belirlenebilir olmasına imkan sağlamaktadır. Hedefleme
çerezlerine izin vermemeniz durumunda, hedeflenmiş reklamlarla karşılaşma olasılığınız düşük olacaktır. Örneğin:
favorilerinize eklemiş olduğunuz ürünlerin yahut sıklıkla ziyaret ettiğiniz sayfaların tespit edilerek tarafınıza
kişiselleştirilmiş ürün önerisi ile hizmet sunulması.</p>

<p><strong><u>1.1.3. İşlevsel Tanımlama Çerezleri:</u></strong> Bu çerezler, web sitemizin gelişmiş bir işlevsellik ve kişiselleştirme sunulmasına
olanak sağlar. İşlevsel tanımlama çerezleri, BuradaGor tarafından veya hizmetlerimizi sayfalarımıza eklediğimiz üçüncü
taraf sağlayıcılar tarafından ayarlanabilir. İşlevsel tanımlama çerezlerine izin vermemeniz halinde bu hizmetlerin
tamamı veya bir kısmı düzgün çalışmayabilir. Örneğin: Dil tercihlerinize dair yaptığınız ayarlar.</p>

<p><strong><u>1.1.4. Zorunlu Tanımlama (Kesinlikle Gerekli) Çerezler:</u></strong> Bu çerezler web sitemizin çalışması için zorunlu olan ve
sistemlerimizce kapatılması mümkün olmayan çerezlerdir. Zorunlu tanımlama çerezleri, kullanıcının talep etmiş olduğu
bir bilgi toplumu hizmetinin (örneğin; log-in olma, form doldurma, gizlilik tercihlerinin hatırlanması gibi) yerine
getirilebilmesi için zorunlu olarak kullanılmaktadırlar. Tarayıcınızı zorunlu tanımlama çerezlerini engelleyecek veya sizi
uyaracak şekilde ayarlayabilirsiniz; ancak bu durumda web sitemizin bazı bölümleri gerektiği gibi çalışmayacaktır.</p>

<p><strong><u>1.2. Sürelerine Göre Çerezler</u></strong></p>

<p><strong><u>BuradaGo olarak, sürelerine göre iki tür çerez kullanmaktayız.</u></strong> Bunlar, oturum çerezleri ve kalıcı çerezlerdir.</p>

<p><strong><u>1.2.1. Oturum (session - non persistent) Çerezleri:</u></strong> Geçici çerez olarak da adlandırılan oturum çerezi, BuradaGo
tarafından oturumun sürekliliğinin sağlanması amacıyla kullanılır. İnternet tarayıcınızı kapattığınızda oturum çerezleri
de kendiliğinden silinmektedir. Örneğin: BuradaGo’yu ilk defa ziyaret ettiğinizin tespiti.</p>

<p><strong>1.2.2. Kalıcı (persistent) Çerezler:</strong> İnternet tarayıcısı kapatıldığı zaman kendiliğinden silinmeyen kalıcı çerezler, belirli bir
tarihte veya belirli bir süre sonra kendiliğinden silinmektedir. Örneğin: internet sitemizdeki hesabınıza giriş yaptığınız
esnada her seferinde giriş bilgilerini tekrar girmemenizi sağlamak.</p>

<p><strong>1.3. Taraflarına göre:</strong></p>

<p><strong>1.3.1. Birinci Taraf Çerezler</strong></p>

<p><strong>Doğrudan ziyaret edilen web sitesi veya BuradaGo tarafından cihaza yerleştirilmektedir.</strong></p>

<p><strong>1.3.2. Üçüncü Taraf Çerezler</strong></p>

<p>BuradaGo ile iş birliği içerisinde olan, reklam veren veya analitik sistem gibi üçüncü bir tarafça cihaza yerleştirilen
çerezlerdir.</p>

<p>İnternet sitemize giriş yaptığınız tarayıcıya bağlı olarak kullanılan çerez türleri de değişkenlik gösterebilecektir.</p>

<p><strong><u>BURADAGO TARAFINDAN ÇEREZ KULLANIMININ HUKUKİ SEBEBİ VE ÇEREZLERİN KULLANIM AMAÇLARI</u></strong></p>
<p>BuradaGo, söz konusu tabloda yer alan amaçlarla KVKK’nın 5. maddesinde belirtilen ve aşağıda yer verilen hukuka
uygunluk sebeplerine dayanarak kişisel verilerinizi işlemektedir. Çerezler vasıtasıyla toplanan kişisel verileriniz;</p>
<p><u>Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli
olması hukuki sebebine dayanarak;</u> Platformun sunduğu hizmetlerden yararlanmanızı sağlamak için zorunlu tanımlama
çerezleri ile temel fonksiyonların gerçekleştirilmesi amacıyla,</p>
<p><u>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayanarak;</u> Platformda
kullanılan tüm çerez tipleri vasıtasıyla işlenen kişisel veriler, hukuki bir ihtilaf olması ya da kamu kurumlarının bilgi
talebinde bulunması halinde hukuk ve dava işlerinin yürütülmesi amacıyla,</p>
<p><u>Temel hak ve özgürlüklerine zarar vermemek kaydıyla şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu
olması hukuki sebebine dayanarak;</u> Platformun performansının ve işlevselliğinin geliştirilmesi ve iyileştirilmesine
yönelik faaliyetlerin yürütülmesi, platformun kullanım kolaylığının sağlanması amacıyla,</p>
<p><u>Açık rızanızın bulunması hukuki sebebine dayanarak;</u> Performans Tanımlama Çerezleri, Hedefleme Amaçlı Tanımlama
Çerezleri ve İşlevsel Tanımlama Çerezleri kullanılarak alışveriş deneyiminizin iyileştirilmesi, hizmetlerimizin sunulması
ve size özel tanıtım yapılması, promosyonlar ve pazarlama tekliflerinin sunulması, web sitesinin veya mobil
uygulamanın içeriğinin size göre iyileştirilmesi ve/veya tercihlerinizin belirlenmesi, memnuniyetinizin arttırılmasına
yönelik faaliyetlerin yürütülmesi, yurt içinde ve yurtdışına kişisel veri aktarımı yapılması amacıyla işlenmektedir.</p>
<p><strong><u>BURADAGO ÇEREZLER VASITASIYLA TOPLADIĞI KİŞİSEL VERİLERİNİZİ KİMLERE HANGİ HUKUKİ SEBEBE DAYANARAK
HANGİ AMAÇLARLA AKTARMAKTADIR?</u></strong></p>
<p>BuradaGo, çerezler vasıtasıyla topladığı kişisel verilerinizi KVKK’nın kişisel verilerin aktarılmasına yönelik hükümlerine
uyumlu şekilde “bilme gereği” ve “kullanma gereği” ilkelerine uygun olarak, veri minimizasyonu ilkesine riayet ederek
ve gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen göstermektedir.</p>
<p>www.buradago.com web sitesi çerezleri; arama motorlarını, web sitesi, mobil uygulamasını ve/veya web sitesinin
reklam verdiği internet sitelerini ziyaret ettiğinizde ilginizi çekebileceğini düşündüğü reklamları size sunabilmek için
"reklam teknolojisini" devreye sokmak amacıyla kullanabilir. Reklam teknolojisi, size özel reklamlar sunabilmek için
web sitesine/mobil uygulamaya ve web sitesinin reklam verdiği web sitelerine/mobil uygulamalarına yaptığınız önceki
ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, web sitesinin sizi tanıyabilmesi amacıyla tarayıcınıza benzersiz
bir üçüncü taraf çerezi yerleştirilebilir. Bu tip üçüncü taraf çerezler vasıtasıyla elde edilen kişisel veriler, sosyal medya
mecraları aracılığıyla BuradaGo’ya giriş yapmanız halinde ilgili sosyal medya mecraları ile paylaşılabilmektedir.</p>
<p>BuradaGo, ayrıca Google Inc. tarafından sağlanan bir web analizi hizmeti olan Google Analytics kullanmaktadır. Google
Analytics, çerezleri kullanıcıların web sitesini, mobil uygulamayı ve/veya mobil sitesini nasıl kullandıklarını istatistiki
bilgiler/raporlar ile analiz etmek amacıyla kullanır. Google Analytics kullanımı hakkında daha fazla bilgi için (reddetme
seçenekleri dahil), burada yer alan adresi ziyaret edebilirsiniz.</p>
<p>BuradaGo, kişisel verilerinizi hizmetlerinin size özelleştirilerek geliştirilmesi, çerez tercihlerinize uygun olarak çerez
yönetimi sürecinin yürütülmesi amaçlarıyla Tedarikçileri ve Hizmet Sağlayıcıları ile paylaşabilmektedir.</p>
<p><strong><u>ÇEREZ KULLANIMINI NASIL KONTROL EDEBİLİRSİNİZ?</u></strong></p>
<p>Zorunlu çerezlerin kullanımı sitemizin çalışması için elzem olduğundan bu çerez türünü yönetebilme imkanınız
bulunmamaktadır.</p>
<p>Aşağıdaki yöntemleri kullanarak da çerez tercihlerinizi yönetebilirsiniz:</p>
<p><u>Google Chrome:</u> Tarayıcınızın adres bölümünde yer alan, "kilit işareti"ni tıklayarak, "çerezler" sekmesinden çerezlere
izin verebilir veya engelleyebilirsiniz.</p>
<p><u>Internet Explorer:</u> Tarayıcınızın sağ üst bölümünde yer alan "Araçlar" bölümünden güvenlik sekmesini tıklayarak "izin
ver" veya "izin verme" şeklinde çerezleri yönetebilirsiniz.</p>
<p><u>Mozilla Firefox:</u> Tarayıcınızın sağ üst köşesinde yer alan "menüyü aç" sekmesini tıklayınız. "Seçenekler" görselini
tıklayarak "Gizlilik ve Güvenlik" butonunu kullanarak çerezleri yönetebilirsiniz.</p>
<p>Opera: Tarayıcınızın "Tercihler" bölümünde "Gelişmiş"i seçerek "çerezler" bölümünden çerez yönetimini yapabilirsiniz.</p>
<p>Safari: Telefonunuzun "Ayarlar" bölümünden "safari" sekmesini seçip, "Gizlilik ve Güvenlik" Bölümünden tüm çerez
yönetiminizi yapabilirsiniz.</p>

<p><strong>Yukarıdaki seçeneklerin yanı sıra;</strong> tüm çerezler hakkında bilgi sahibi olmak ve çerez yönetimi için:<br/>
<a href="https://www.allaboutcookies.org">https://www.allaboutcookies.org</a>, <a href="https://www.youronlinechoices.eu/">https://www.youronlinechoices.eu/</a> adresini ziyaret edebilirsiniz veya "<strong>Privacy Badger</strong>" uygulamasını kullanabilirsiniz (<a href="https://www.eff.org/tr/privacybadger">https://www.eff.org/tr/privacybadger</a>). Kalıcı çerezleri veya oturum çerezlerini
reddederseniz, web sitesini, mobil uygulamayı ve mobil sitesini kullanmaya devam edebilirsiniz fakat web sitesinin,
mobil uygulamanın ve mobil sitesinin tüm işlevlerine erişemeyebilirsiniz veya erişiminiz sınırlı olabilir.</p>


        </p>
        </div>

        </div>

    );


}

export default Cerez;