import React from 'react'
import { FaRegEdit } from "react-icons/fa";

const IndividualAddress = (props) => {
  return (
    <div className='bg-gray-400 mx-2  rounded-md'>
      {/* address title/name */}
      <div className='flex items-center justify-center'>
        <p className='text-gray-700 underline'
        >
            {props.address.address_name} 
        </p>
        <FaRegEdit onClick={() => { props.onEditAddress(props.address) }}
         className=' mb-3 ml-6 cursor-pointer'/>
      </div>

        <div className='text-start pl-[1rem]'>
            <p>{props.address.full_name}</p> {/**address owner full name */}
            <p>{props.address.phone}</p> {/** phone*/}
            <p>{props.address.district+'/'+props.address.province}</p> {/**district/province */}
            <p className='text-gray-600'>{props.address.address}</p> {/** street address*/}
            <p className=''>{props.address.country}</p> {/** country*/}
        </div>
    </div>
  )
}

export default IndividualAddress