import UyeOlForm from "../components/uye-form";
import "../App.css";
import Footer from "../components/footer";


const UyeOlPage = () =>{

    return(
        <div>
        <div className="register">

        <UyeOlForm/>
        
        </div>
        <Footer/> 

        </div>
    );
}

export default UyeOlPage;